/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import {
  ChromeOutlined,
  GroupOutlined,
  MailOutlined,
  PhoneOutlined,
  ProfileOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

const ContactSalesComponent = () => {
  const dispatch = useDispatch();
  const [notificationApi, contextHolder] = notification.useNotification();

  const openNotification = () => {
    notificationApi.open({
      message: "Thank You!",
      description: "Our sales team will get in touch with you shortly",
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };
  const lmState = useSelector((x) => x?.LoginModel);
  const onFinish = async (values) => {
    try {
      const payload = { ...values };
      await dispatch?.LoginModel?.requestPostContactSales(payload);
      openNotification();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {contextHolder}
      <Form
        name="normal_ContactSalesComponent"
        style={{ width: "40vw" }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        size="large"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your name!",
              },
            ]}
          >
            <Input
              style={{ width: 280, marginRight: 10 }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your name!",
              },
            ]}
          >
            <Input
              style={{ width: 270 }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Last Name"
            />
          </Form.Item>
        </div>

        <Form.Item
          name="designation"
          rules={[
            {
              required: true,
              message: "Please enter your designation!",
            },
          ]}
        >
          <Input
            prefix={<ProfileOutlined className="site-form-item-icon" />}
            placeholder="Your Designation"
          />
        </Form.Item>

        <Form.Item
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please enter your Organization!",
            },
          ]}
        >
          <Input
            prefix={<GroupOutlined className="site-form-item-icon" />}
            placeholder="Your Organization"
          />
        </Form.Item>
        <Form.Item
          name="company_website"
          rules={[
            {
              required: true,
              message: "Please enter your company's website!",
            },
          ]}
        >
          <Input
            prefix={<ChromeOutlined className="site-form-item-icon" />}
            placeholder="Company Website"
          />
        </Form.Item>
        <Form.Item
          name="industry"
          rules={[
            {
              required: true,
              message: "Please enter your industry!",
            },
          ]}
        >
          <Select
            placeholder="Industry"
            prefix={<GroupOutlined className="site-form-item-icon" />}
          >
            <Select.Option value="automotive">Automotive</Select.Option>
            <Select.Option value="healthcare">Healthcare</Select.Option>
            <Select.Option value="pharmaceutical">Pharmaceutical</Select.Option>
            <Select.Option value="insurance">Insurance</Select.Option>
            <Select.Option value="telecommunications">
              Telecommunications
            </Select.Option>
            <Select.Option value="banking">Banking</Select.Option>
            <Select.Option value="realEstate">Real Estate</Select.Option>
            <Select.Option value="retail">Retail</Select.Option>
            <Select.Option value="hospitality">Hospitality</Select.Option>
            <Select.Option value="technology">Technology</Select.Option>
            <Select.Option value="electronics">Electronics</Select.Option>
            <Select.Option value="manufacturing">Manufacturing</Select.Option>
            <Select.Option value="aerospace">Aerospace</Select.Option>
            <Select.Option value="energy">Energy</Select.Option>
            <Select.Option value="logistics">Logistics</Select.Option>
            <Select.Option value="agriculture">Agriculture</Select.Option>
            <Select.Option value="education">Education</Select.Option>
            <Select.Option value="media">Media and Entertainment</Select.Option>
            <Select.Option value="consumerGoods">Consumer Goods</Select.Option>
            <Select.Option value="construction">Construction</Select.Option>
            <Select.Option value="finance">Finance</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Your Official Email ID"
          />
        </Form.Item>
        <Form.Item
          name="mobile_number"
          rules={[
            {
              required: true,
              message: "Please enter your number!",
            },
          ]}
        >
          <Input
            prefix={<PhoneOutlined className="site-form-item-icon" />}
            placeholder="Phone Number"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {lmState?.loading ? (
              <Spin style={{ color: "white", borderColor: "white" }} />
            ) : (
              "Contact Sales"
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ContactSalesComponent;
