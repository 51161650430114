/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useEffect, useState } from "react";
import TableOptions from "common/TableOptions";
import { PATH } from "constants/PATH";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTableColumns } from "utils/tableUtils";
import { getCapitalizeFirstLetter, getDataIsEmpty } from "utils";
import { FlexBoxVertical } from "styles/styles";
import Modal from "common/Modal";
import FileUpload from "common/FileUpload";
import { Breadcrumb, Card, Spin, Typography } from "antd";
import { FileUploadWrapper } from "./styles";

const { Paragraph } = Typography;
const Interviews = () => {
  const dispatch = useDispatch();
  const { interviews } = useSelector((state) => state?.InterviewModel);
  const [tableColumns, setTableColumns] = useState([]);
  const [interviewList, setInterviewList] = useState([]);
  const [isUploadReport, setIsUploadReport] = useState(false);
  const [selectedInterviewId, setSelectedInterviewId] = useState();
  const imodel = useSelector((x) => x.InterviewModel);

  const { loading } = imodel;

  const [pdfFile, setPdfFile] = useState();

  const navigate = useNavigate();
  const getColumns = () => {
    const columns = Admin?.interviewList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  };

  useEffect(() => {
    dispatch.InterviewModel.requestGetInterviewList();
    getColumns();
  }, []);

  useEffect(() => {
    if (interviews?.length > 0) {
      setInterviewList(interviews);
    }
  }, [interviews]);

  const handleGenerateTranscript = (interviewUUID) => {
    alert("Generating Transcript..");
    dispatch.InterviewModel.generateInterviewTranscript(interviewUUID);
  };
  const handleUploadReport = (interviewID) => {
    setIsUploadReport(!isUploadReport);
    setSelectedInterviewId(interviewID);
  };
  const handleCancelModal = () => {
    setIsUploadReport(!isUploadReport);
    setPdfFile(null);
    setSelectedInterviewId(null);
  };

  const handleSubmitReport = () => {
    const formData = new FormData();
    formData.append("final_report", pdfFile);
    formData.append("interview", selectedInterviewId);
    dispatch.InterviewModel.requestUploadReport(formData);
    setIsUploadReport(!isUploadReport);
    setSelectedInterviewId(null);
  };
  const handleFileChange = (data) => {
    setPdfFile(data);
  };
  return (
    <FlexBoxVertical>
      <Breadcrumb
        items={[{ title: "Assessments", href: PATH.Interviews }]}
      ></Breadcrumb>
      <TableOptions
        title="Assessments"
        btnText="Create Assessment"
        path={PATH.AddInterview}
      />
      {loading ? (
        <Spin></Spin>
      ) : (
        <>
          <CustomTable
            columns={tableColumns}
            data={
              interviewList?.length > 0 &&
              interviewList
                .sort((a, b) => b.id - a.id)
                .map((items, i) => {
                  const {
                    id,
                    interview_report,
                    interview_status,
                    interview_link,
                    interview_uuid,
                    passcode,
                    job_detail,
                    user_profile,
                    report_uploaded,
                  } = items;
                  let combinedName = `${user_profile?.first_name} ${user_profile?.last_name}`;
                  const obj = {
                    key: i,
                    id: id,
                    interview_report: interview_report,
                    report_uploaded,
                    interview_status: interview_status,
                    interview_link: interview_link,
                    passcode: passcode,
                    job_title: getCapitalizeFirstLetter(job_detail?.title),
                    candidate_name:
                      user_profile?.first_name || user_profile?.last_name
                        ? getCapitalizeFirstLetter(combinedName)
                        : getDataIsEmpty(),
                    generateTranscript: () =>
                      handleGenerateTranscript(interview_uuid),
                    uploadReport: () => handleUploadReport(id),
                  };
                  return obj;
                })
            }
            // onRowClick={(record) => {
            //   navigate(`${PATH.InterviewDetails}/${record?.id}`);
            // }}
          />
          {isUploadReport && (
            <Modal
              title="Upload Report"
              centered={true}
              okText="Upload"
              cancelText="Cancel"
              open={isUploadReport}
              closeIcon={true}
              onCancel={handleCancelModal}
              handleSubmit={handleSubmitReport}
            >
              <FileUploadWrapper>
                <Paragraph>Upload an PDF</Paragraph>
                <FileUpload onFileUpload={handleFileChange} accept={".pdf"} />
              </FileUploadWrapper>
            </Modal>
          )}
        </>
      )}
    </FlexBoxVertical>
  );
};

export default Interviews;
