/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import { CREATE_CANDIDATE_URL, LIST_CANDIDATE_URL, CREATE_CANDIDATE_BULK } from "constants/api";

const model = {
  state: {
    candidates: [],
    loading: false,
    error: "",
    editCandidateModalState: {isOpen : false}
  },
  reducers: {
    setEditCandidateModalState: (state, payload) => {
      return {...state, editCandidateModalState: payload}
    },
    setCandidates: (state, payload) => {
      return {
        ...state,
        candidates: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestGetCandidateList() {
      try {
        this.setLoadingState(true);
        const response = await api.get(LIST_CANDIDATE_URL);
        if (response) {
          this.setLoadingState(false);
          this.setCandidates(response);
        }
        return response
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data
      }
    },
    async toggleEditCandidateModalState(payload) {
      this.setEditCandidateModalState(payload);
    },
    async requestCreateCandidate(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CREATE_CANDIDATE_URL, payload);
        if (response)
          return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data
      }
    },
    async requestCandidateBulkCreate(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CREATE_CANDIDATE_BULK, payload);
        if (response)
          return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data
      }
    }
  }),
};
export default model;
