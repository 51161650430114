/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import { LOGIN_URL, CONTACT_SALES_URL } from "constants/api";
import { setItemToLocalStorage } from "utils";

// Defining the LoginModel
const model = {
  state: {
    login: {}, //inital state of login state
    loading: false,
    error: "",
  },
  reducers: {
    // Reducer to set the login state
    setLoginState: (state, payload) => {
      return {
        ...state,
        login: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    // Reducer to set the error state
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestPostLogin(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(LOGIN_URL, payload);
        this.setLoginState(response);
        console.log("setItemToLocalStorage data", response);
        setItemToLocalStorage("data", response);
        return response;
      } catch (err) {
        alert(err.toString());
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      }
      finally {
        this.setLoadingState(false);
      }
    },
    async requestPostContactSales(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CONTACT_SALES_URL, payload);
        this.setLoadingState(false);
        this.setLoginState(response);
        console.log("setItemToLocalStorage data", response);
        setItemToLocalStorage("data", response);
        return response;
      } catch (err) {
        alert(err.toString());
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      }
    },
  }),
};
// Exporting the LoginModel
export default model;
