export const PATH = {
  Root: "/",
  SAInterviews: "/sa-interviews",
  SACandidates: "/sa-candidates",
  SAJobProfiles: "/sa-job-profiles",
  SAUsers: "/sa-users",
  ContactSales: "/contact-sales",
  Dashboard: "/dashboard",
  Interviews: "/interviews",
  Jobroles: "/jobroles",
  AddJob: "/add-job",
  Login: "/login",
  Logout: "/logout",
  NotFound: "/not-found",
  JobDetails: "/job-details",
  AddInterview: "/add-interview",
  InterviewDetails: "/interview-details",
  Employees: "/employees",
  EmployeeDetails: "/employee-details",
  AddEmployee: "/add-employee",
  Companies: "/companies",
  UploadReport: "/upload-report",
  CreateReport: "/create-report",
  AddCompany: "/add-company",
  CompanyDetails: "/company-details",
  Candidates: "/candidates",
  BulkAddInterviews: "/bulk-add-interviews",
  AddCandidate: "/add-candidate",
  CandidateDetails: "/candidate-details",
  Profile: "/profile",
  Users: "/users",
  CreateRoleplay: "/create-roleplay",
  AssignRoleplay: "/assign-roleplay",
  RoleplayResults: "/roleplay-results",
  ViewRoleplay: "/view-roleplay",
  CreateLink: "/create-link",
};

export const STATUS_CODE = [200];
