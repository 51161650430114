/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { PATH } from "constants/PATH";
import AddInterview from "pages/AddInterview";
import AddJob from "pages/AddJob";
import AssignRoleplayPage from "pages/AssignRoleplay/AssignRoleplay";
import BulkAddInterviews from "pages/BulkAddInterview";
import Candidates from "pages/Candidates";
import Companies from "pages/Companies";
import AddCompany from "pages/Companies/AddCompany";
import CompanyDetails from "pages/Companies/CompanyDetails";
import ContactSalesPage from "pages/ContactSales";
import CreateCandidate from "pages/CreateCandidate";
import CreateReport from "pages/CreateReport";
import CreateRoleplayPage from "pages/CreateRoleplay/CreateRoleplay";
import Dashboard from "pages/Dashboard";
import EmployeeDetails from "pages/EmployeeDetails";
import Employees from "pages/Employees";
import InterviewDetails from "pages/InterviewDetails";
import Interviews from "pages/Interviews";
import JobDetails from "pages/JobDetails";
import JobRoles from "pages/JobRoles";
import Auth from "pages/Login";
import PageNotFound from "pages/PageNotFound";
import Profile from "pages/Profile";
import RoleplayResultsPage from "pages/RoleplayResults/RoleplayResults";
import UploadReport from "pages/UploadReport";
import Users from "pages/Users";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getItemFromLocalStorage } from "utils";
import PrivateRoutes from "./privateRoute";
import ViewRoleplay from "pages/ViewRoleplay";
import CreateLinkPage from "pages/CreateLink/CreateLink";
// import SuperAdminCandidates from "pages/SuperAdminCandidates";
// import SuperAdminJobProfiles from "pages/SuperAdminJobProfiles";
// import SuperAdminInterviews from "pages/SuperAdminInterviews";
// import SuperAdminUsers from "pages/SuperAdminUsers";

function AppRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const data = getItemFromLocalStorage("data");

  const handleLogin = () => {
    setIsAuthenticated(true);
    alert("Logged In Successfully");
  };

  useEffect(() => {
    const data = getItemFromLocalStorage();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
            {/* */}
            <Route path={PATH.Dashboard} element={<Dashboard />} />
            <Route
              path={`${PATH.ViewRoleplay}/:id`}
              element={<ViewRoleplay />}
            />
            <Route path={PATH.UploadReport} element={<UploadReport />} />
            <Route path={PATH.CreateReport} element={<CreateReport />} />
            <Route path={PATH.SACandidates} element={<></>}></Route>
            <Route path={PATH.SAJobProfiles} element={<></>}></Route>
            <Route path={PATH.SAInterviews} element={<></>}></Route>
            <Route path={PATH.SAUsers} element={<></>}></Route>
            {/* */}
            <Route path={PATH.Interviews} element={<Interviews />} />
            <Route path={PATH.AddJob} element={<AddJob />} />
            <Route path={PATH.Jobroles} element={<JobRoles />} />
            <Route path={`${PATH.JobDetails}/:id`} element={<JobDetails />} />
            <Route path={PATH.AddInterview} element={<AddInterview />} />
            <Route
              path={`${PATH.InterviewDetails}/:id`}
              element={<InterviewDetails />}
            />
            <Route path={PATH.Employees} element={<Employees />} />
            <Route path={PATH.EmployeeDetails} element={<EmployeeDetails />} />
            <Route path={PATH.Companies} element={<Companies />} />
            <Route path={PATH.Users} element={<Users />} />
            <Route
              path={`${PATH.CompanyDetails}/:id`}
              element={<CompanyDetails />}
            />
            <Route path={PATH.AddCompany} element={<AddCompany />} />
            <Route path={PATH.Candidates} element={<Candidates />} />
            <Route
              path={PATH.CreateRoleplay}
              element={<CreateRoleplayPage />}
            />
            <Route path={PATH.CreateLink} element={<CreateLinkPage />} />
            <Route
              path={PATH.AssignRoleplay}
              element={<AssignRoleplayPage />}
            />
            <Route
              path={PATH.RoleplayResults}
              element={<RoleplayResultsPage />}
            />
            <Route path={PATH.AddCandidate} element={<CreateCandidate />} />
            <Route
              path={PATH.BulkAddInterviews}
              element={<BulkAddInterviews />}
            />
            <Route path={PATH.Profile} element={<Profile />} />
          </Route>
          <Route
            path={PATH.Login}
            element={<Auth handleLogin={handleLogin} />}
          />
          <Route path={PATH.ContactSales} element={<ContactSalesPage />} />
          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;
