/* eslint-disable import/no-unresolved */
import React from "react";
import { Breadcrumb, Card, Typography } from "antd";
import AddJobForm from "components/AddJob";
import { PATH } from "constants/PATH";
const { Title } = Typography;
const AddJob = () => {
  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          { title: "Job Profiles", href: PATH.Candidates },
          {
            title: <Typography.Text strong>Add Job Profile</Typography.Text>,
            href: PATH.AddJob,
          },
        ]}
      ></Breadcrumb>
      <Card>
        <Title level={4}>Add Job Profile</Title>
        <AddJobForm />
      </Card>
    </>
  );
};

export default AddJob;
