import {
  Typography,
  // Input,
  AutoComplete,
  Button,
  // Table,
  // Checkbox,
} from "antd/es";
import CenteredSpin from "../../components/CenteredSpin";
import { useEffect, useState } from "react";
import { api } from "../../axiosRequest";
import { RA_MERGE_CREATE, RA_MERGE_GET_TARGETS } from "../../constants/api";
import { Divider } from "antd";

// const candidateDataSource = [
//   { checked: false, name: "Ramesh Kumar", email: "ramesh@gmail.com", id: 1 },
//   { checked: false, name: "Suresh Kumar", email: "suresh@gmail.com", id: 2 },
// ];

const CreateLinkPage = () => {
  const [roleplayDataSource, setRoleplayDataSource] = useState([]);
  const [assessmentDataSource, setAssessmentDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const [roleplayOptions, setRoleplayOptions] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedRoleplay, setSelectedRoleplay] = useState(null);
  const [generatedLink, setGeneratedLink] = useState(null);
  // const [searchText, setSearchText] = useState("");

  // const [selectedCandidates, setSelectedCandidates] = useState({
  //   "Suresh Kumar": false,
  //   "Ramesh Kumar": false,
  // });

  const handleSearchAssessment = (value) => {
    const filteredOptions = assessmentDataSource.filter(
      (x) => x.value.toLowerCase().indexOf(value) !== -1
    );
    setAssessmentOptions(filteredOptions);
  };

  const handleSearchRoleplay = (value) => {
    const filteredOptions = roleplayDataSource.filter(
      (x) => x.value.toLowerCase().indexOf(value) !== -1
    );
    setAssessmentOptions(filteredOptions);
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get(RA_MERGE_GET_TARGETS)
      .then(async (r) => {
        setAssessmentDataSource(
          r.interviews.map((x) => ({
            ...x,
            label: `Candidate - ${x.candidate_full_name} || Interview ID ${x.interview_id} || Profile - ${x.job_title}`,
            value: `Candidate - ${x.candidate_full_name} || Interview ID ${x.interview_id} || Profile - ${x.job_title}`,
          }))
        );
        setAssessmentOptions(
          r.interviews.map((x) => ({
            ...x,
            label: `Candidate - ${x.candidate_full_name} || Interview ID ${x.interview_id} || Profile - ${x.job_title}`,
            value: `Candidate - ${x.candidate_full_name} || Interview ID ${x.interview_id} || Profile - ${x.job_title}`,
          }))
        );
        setRoleplayDataSource(
          r.roleplays.map((x) => ({
            ...x,
            label: `Candidate - ${x.candidate_full_name} || Scenario - ${x.scenario_text} || Roleplay ID ${x.instance_uuid}`,
            value: `Candidate - ${x.candidate_full_name} || Scenario - ${x.scenario_text} || Roleplay ID ${x.instance_uuid}`,
          }))
        );
        setRoleplayOptions(
          r.roleplays.map((x) => ({
            ...x,
            label: `Candidate - ${x.candidate_full_name} || Scenario - ${x.scenario_text} || Roleplay ID ${x.instance_uuid}`,
            value: `Candidate - ${x.candidate_full_name} || Scenario - ${x.scenario_text} || Roleplay ID ${x.instance_uuid}`,
          }))
        );
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }, []);

  // const filteredData = candidateDataSource.filter((x) =>
  //   searchText ? x.name.toLowerCase().includes(searchText?.toLowerCase()) : x
  // );

  if (isLoading) {
    return <CenteredSpin />;
  } else
    return (
      <div
        style={{
          width: "100%",
          height: "85vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: 10,
          padding: 20,
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography.Title level={4}>Generate Mobile Link</Typography.Title>
        <Typography.Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 10,
            textAlign: "left",
            width: "100%",
          }}
        >
          Select a roleplay
        </Typography.Text>
        <AutoComplete
          style={{
            width: "100%",
            marginBottom: 20,
            borderRadius: 5,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          options={roleplayOptions}
          value={selectedRoleplay}
          onChange={(e) => setSelectedRoleplay(e)}
          onSearch={handleSearchRoleplay}
          placeholder="Search"
        />
        <Typography.Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 10,
            textAlign: "left",
            width: "100%",
          }}
        >
          Select an assessment
        </Typography.Text>
        <AutoComplete
          style={{
            width: "100%",
            marginBottom: 20,
            borderRadius: 5,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          value={selectedAssessment}
          onChange={(e) => setSelectedAssessment(e)}
          options={assessmentOptions}
          onSearch={handleSearchAssessment}
          placeholder="Search"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography.Text style={{ fontWeight: "bold" }}>
            Selected Assessment
          </Typography.Text>
          {selectedAssessment ? (
            selectedAssessment
              .split("|")
              .map((x) => <Typography.Text key={x}>{x}</Typography.Text>)
          ) : (
            <Typography.Text>N/A</Typography.Text>
          )}
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography.Text style={{ fontWeight: "bold" }}>
            Selected Roleplay
          </Typography.Text>
          {selectedRoleplay ? (
            selectedRoleplay
              .split("|")
              .map((x) => <Typography.Text key={x}>{x}</Typography.Text>)
          ) : (
            <Typography.Text>N/A</Typography.Text>
          )}
        </div>
        <Button
          disabled={isLoading}
          style={{
            marginTop: 10,
            maxWidth: 200,
            fontSize: 14,
            backgroundColor: "navy",
            color: "white",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);
            let roleplay_instance_uuid = null;
            let interview_id = null;
            for (let i of assessmentDataSource) {
              if (i.value === selectedAssessment) {
                interview_id = i.interview_id;
              }
            }
            for (let i of roleplayDataSource) {
              if (i.value === selectedRoleplay) {
                roleplay_instance_uuid = i.instance_uuid;
              }
            }
            console.log(interview_id);
            console.log(roleplay_instance_uuid);
            if (!roleplay_instance_uuid)
              return alert("No roleplay instance ID");
            if (!interview_id) return alert("No interview ID");
            const res = await api.post(RA_MERGE_CREATE, {
              interview_id,
              roleplay_instance_uuid,
            });
            console.log(res);
            if (res.link) setGeneratedLink(res.link);
            else
              alert("Error while generating link, no link found in response");

            setIsLoading(false);
          }}
        >
          Generate Link
        </Button>
        {generatedLink ? (
          <div style={{marginTop: 10}}>
            <Typography.Text>Generated Link : <a href={generatedLink}>{generatedLink}</a></Typography.Text>
          </div>
        ) : null}
      </div>
    );
};

export default CreateLinkPage;
