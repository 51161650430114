/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { Typography } from "antd";
import { Breadcrumb, Spin } from "antd";
import TableOptions from "common/TableOptions";
import CustomTable from "components/CustomTable";
import { PATH } from "constants/PATH";
import { Admin } from "constants/Tables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexBoxVertical } from "styles/styles";
import { createTableColumns } from "utils/tableUtils";

const JobRoles = () => {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state?.JobModel);
  const [tableColumns, setTableColumns] = useState([]);
  const [jobList, setJobList] = useState([]);
  const navigate = useNavigate();
  const { loading } = useSelector((x) => x.JobModel);

  const getColumns = () => {
    const columns = Admin?.jobRoleList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  };

  useEffect(() => {
    dispatch.JobModel.requestGetJobList();
    getColumns();
  }, []);

  useEffect(() => {
    if (jobs?.length > 0) {
      setJobList(jobs);
    }
  }, [jobs]);

  return (
    <FlexBoxVertical>
      <Breadcrumb
        items={[
          { title: "Job Profiles", href: PATH.Jobroles },
        ]}
      ></Breadcrumb>
      <TableOptions
        title="Job Profiles"
        btnText="Add Job Profiles"
        path="/add-job"
      />
      {loading ? (
        <Spin></Spin>
      ) : (
        <CustomTable
          data={jobList?.sort((a, b) => b.id - a.id)}
          columns={tableColumns}
          onRowClick={(record) => {
            navigate(`${PATH.JobDetails}/${record?.id}`);
          }}
        />
      )}
    </FlexBoxVertical>
  );
};

export default JobRoles;
