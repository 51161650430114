/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { Breadcrumb, Card } from "antd";
import AddInterviewForm from "components/AddInterviewForm";
import { useDispatch, useSelector } from "react-redux";
import { Admin } from "constants/Tables";
import { createTableColumns } from "utils/tableUtils";
import { PATH } from "constants/PATH";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";

const AddInterview = () => {
  const dispatch = useDispatch();
  const { jobs, candidates } = useSelector((state) => ({
    jobs: state?.JobModel.jobs,
    candidates: state?.CandidateModel.candidates,
  }));
  const [jobList, setJobList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getColumns = () => {
    const columns = Admin?.candidateList?.list || [];
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  };

  useEffect(() => {
    dispatch.JobModel.requestGetJobList();
    dispatch.CandidateModel.requestGetCandidateList();
    getColumns();
  }, []);

  useEffect(() => {
    if (jobs?.length > 0) {
      const filteredJobList = jobs.map(({ id, title }) => ({ id, title }));
      setJobList(filteredJobList);
    }
    if (candidates?.length > 0) {
      setCandidateList(candidates);
    }
  }, [jobs, candidates]);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
    // setSelectedCandidates([]); // Reset selected candidates when job changes
  };

  const handleCandidateSelect = (selectedRowKeys, selectedRows) => {
    // You can perform additional logic here if needed
    setSelectedCandidates(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };
  const navigate = useNavigate();
  const handleCreateInterview = async () => {
    const selectedCandidateIds = selectedCandidates.map((candidate) =>
      String(candidate.id)
    );
    if (!selectedJob) return alert("no job selected");
    if (
      !selectedCandidateIds ||
      (Array.isArray(selectedCandidateIds) && selectedCandidateIds.length < 1)
    )
      return alert("no candidates selected ");
    const payload = {
      job_id: selectedJob,
      candidate_id_list: selectedCandidateIds,
    };
    await dispatch.InterviewModel.requestCreateInterview(payload);
    navigate(PATH.Interviews);
  };

  return (
    <>
      <Breadcrumb
        items={[
          { title: "Assessments", href: PATH.Interviews },
          {
            title: <Typography.Text strong>Add Assessment</Typography.Text>,
            href: PATH.AddInterview,
          },
        ]}
      ></Breadcrumb>
      <Card>
        <AddInterviewForm
          jobList={jobList}
          tableColumns={tableColumns}
          candidateList={candidateList}
          handleJobSelect={handleJobSelect}
          handleCandidateSelect={handleCandidateSelect}
          handleCreateInterview={handleCreateInterview}
          selectedJob={selectedJob}
          selectedCandidates={selectedCandidates}
          selectable={Admin.candidateList.selectable}
          selectedRowKeys={selectedRowKeys}
        />
      </Card>
    </>
  );
};

export default AddInterview;
