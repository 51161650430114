import { Tag } from "antd";
import React from "react";
import styled from "styled-components";

const StyledTag = styled(Tag)`
      margin:6px;
      border:1px solid ${props => `${props.color} !important`};
      border-radius:${props => `${props?.bordered ? "5px" : "5px"}`};
      font-weight:700;
      font-size:${props => `${props?.bordered} ? "30px" :"30px" } !important`};
      line-height:12.1px;
      background-color:${props => `${props.bg_color} !important`};
      color:${props => `${props?.textColor ? props?.textColor : "white"} !important`};
`
const TagComponent = (props) => {
  const { bordered, color, label, bgColor, textColor } = props;

  return (
    <>
      <StyledTag bordered={bordered} color={color} textColor={textColor} bg_color={bgColor} >
        {label}
      </StyledTag>
    </>
  );
};

export default TagComponent;
