/* eslint-disable import/no-unresolved */
import {
  CheckCircleOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { Badge, Button, Tooltip, Typography } from "antd";
import { api } from "axiosRequest";
import TagComponent from "common/Tag";
import ModifyQuestionButton from "components/ModifyQuestionButton";
import {
  INTERVIEW_MAIL_TEMPLATE_COPY_URL,
  INTERVIEW_SEND_MAIL_URL,
} from "constants/api";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ActiveStatusCheckBox,
  ActiveStatusCheckBoxWrapper,
  ConditionalFlexBoxCentered,
  FlexBoxVerticalCenter,
  StatusBadge,
} from "styles/styles";
import { getCapitalizeFirstLetter, getStatusColor } from "utils";

const getQuestionActions = (record) => {
  return <ModifyQuestionButton record={record}></ModifyQuestionButton>;
};

const InterviewActionButton = ({ record }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Tooltip title="Send Email Link">
        <Button
          type="link"
          onClick={async () => {
            if (record.id) {
              await api.get(INTERVIEW_SEND_MAIL_URL + "/" + record.id);
              alert("Email sent");
            }
          }}
          icon={<SendOutlined style={{ marginRight: 10 }} />}
        ></Button>
      </Tooltip>
      <Tooltip title="Copy Assessment Details to Clipboard">
        <Button
          type="link"
          onClick={async () => {
            if (record.id) {
              const res = await api.get(
                INTERVIEW_MAIL_TEMPLATE_COPY_URL + "/" + record.id
              );
              console.log(res.template);
              if (res.template) {
                navigator.clipboard.writeText(res.template);
                alert("Copied to clipboard!");
              }
            }
          }}
          icon={<CopyOutlined style={{ marginRight: 10 }} />}
        ></Button>
      </Tooltip>
      <Tooltip title="View Assessment">
        <Button
          type="link"
          onClick={() => navigate(`/interview-details/${record.id}`)}
          icon={<InfoCircleOutlined style={{ marginRight: 10 }} />}
        ></Button>
      </Tooltip>
    </div>
  );
};

const NavigateToInterivew = ({ record, dataIndex }) => {
  const navigate = useNavigate();
  let val = record[dataIndex];
  return (
    <a
      href={record.id ? "/interview-details/" + record.id : "#"}
      style={{ textDecoration: "underline" }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        record.id ? navigate("/interview-details/" + record.id) : null;
      }}
    >
      {val}
    </a>
  );
};
const renderInterviewNav = (record, dataIndex) => {
  return <NavigateToInterivew record={record} dataIndex={dataIndex} />;
};

export const CandidateListActionButton = ({ record }) => {
  const dispatch = useDispatch();
  return (
    <Button
      icon={<EditOutlined></EditOutlined>}
      onClick={async () => {
        await dispatch?.CandidateModel?.toggleEditCandidateModalState({
          isOpen: true,
          ...record,
        });
      }}
    >
      Edit
    </Button>
  );
};

const { Paragraph } = Typography;
export const createTableColumns = ({ columns }) => {
  if (!columns) return [];
  if (columns) {
    return columns.map((c) => {
      const {
        title,
        dataIndex,
        width = "auto",
        align = "left",
        activeStatus,
        activeCheckbox,
        sort,
        statusTag,
        combinedAddress,
        download,
        count,
        interviewStatus,
        action,
        audio,
        copy,
        navigateToId,
        zInterviewAction,
        zCandidateListActions,
        zFileDownload,
      } = c;
      const actualSort = (a, b) => {
        if (dataIndex === "interview_status") {
          return a[dataIndex]?.localeCompare(b[dataIndex]) || 0;
        } else {
          return a[dataIndex] - b[dataIndex];
        }
      };
      const sorter = sort ? actualSort : false;

      const render = (_, record) => {
        let comp = record[dataIndex];
        if (dataIndex === "action2") comp = getQuestionActions(record);
        if (activeStatus)
          comp = getStatusComponent(record, dataIndex, align === "center");
        if (activeCheckbox)
          comp = getCheckboxComponent(record, dataIndex, align === "center");
        if (statusTag) comp = renderStatusTag(record, dataIndex);
        if (combinedAddress) comp = combineAddressFields(record);
        if (download) comp = renderDownload(record[dataIndex]);
        if (count) comp = getCount(record[dataIndex]);
        if (interviewStatus) comp = renderInterviewStatus(record, dataIndex);
        if (action) comp = renderAction(record, dataIndex);
        if (audio) comp = renderAudio(record, dataIndex);
        if (copy) comp = renderTextCopy(record, dataIndex);
        if (zInterviewAction) comp = <InterviewActionButton record={record} />;
        if (zFileDownload)
          comp = (
            <Button
              disabled={!record?.interview_report}
              onClick={() => {
                if (record.report_uploaded && record.interview_report) {
                  window.location.assign(record.interview_report);
                }
                // record?.report_uploaded
                //   ? window.location.assign(
                //       JSON.stringify(record?.interview_report)
                //     )
                //   : null;
              }}
            >
              Download
            </Button>
          );
        if (zCandidateListActions)
          comp = <CandidateListActionButton record={record} />;
        if (navigateToId) comp = renderInterviewNav(record, dataIndex);

        return comp;
      };

      return {
        title,
        dataIndex,
        width,
        align,
        key: title,
        sorter,
        render,
      };
    });
  }
};

const getStatusComponent = (record, key, isCenter) => {
  const statusColor = getStatusColor(record[key]);
  const isCustomTooltip = record[`${key}__tooltip`] || "";
  return (
    <Tooltip title={isCustomTooltip}>
      <ConditionalFlexBoxCentered center={isCenter}>
        <StatusBadge color={statusColor} />
      </ConditionalFlexBoxCentered>
    </Tooltip>
  );
};

const getCheckboxComponent = (record, key, isCenter) => {
  const isVerified = record[key];
  const onClick = record["onClick"];
  return (
    <ConditionalFlexBoxCentered center={isCenter}>
      <ActiveStatusCheckBoxWrapper
        isClickable={onClick}
        onClick={() => onClick && onClick(record)}
      >
        <ActiveStatusCheckBox status={isVerified} />
      </ActiveStatusCheckBoxWrapper>
    </ConditionalFlexBoxCentered>
  );
};

const renderStatusTag = (record, dataIndex) => {
  const { is_verified } = record;
  const possibleStatus = ["is_active", "active_status"];
  let statusColor, statusLabel;
  if (dataIndex == "is_verified") {
    statusColor = getStatusColor(is_verified);
    statusLabel = is_verified ? "Verified" : "UnVerified";
  } else if (possibleStatus.includes(dataIndex)) {
    statusColor = getStatusColor(record[dataIndex]);
    statusLabel = record[dataIndex] ? "Active" : "Inactive";
  }
  return (
    <>
      <TagComponent
        bordered={false}
        color={statusColor}
        label={getCapitalizeFirstLetter(statusLabel)}
      />
    </>
  );
};

const combineAddressFields = (record) => {
  const addressFields = [
    record["address_field1"],
    record["address_field2"],
    record["address_field3"],
    record["city"],
  ].filter(Boolean);
  return addressFields.join(", ");
};

const renderDownload = (downloadUrl) => {
  const handleDownloadClick = (e) => {
    e.stopPropagation();
  };

  if (downloadUrl) {
    return (
      <a href={downloadUrl} download onClick={handleDownloadClick}>
        <Tooltip title="Download File">
          <FlexBoxVerticalCenter style={{ gap: "10px" }}>
            <DownloadOutlined />
            <span>Download</span>
          </FlexBoxVerticalCenter>
        </Tooltip>
      </a>
    );
  }

  return (
    <Badge
      status="processing"
      size="medium"
      style={{
        fontSize: ".6rem",
        backgroundColor: "rgb(209 213 219)",
        color: "black",
      }}
      count="N/A"
    />
  );
};

const getCount = (data) => {
  return data.length;
};

// const InterviewStatusColor = {
//   pending: "#1890ff", // Blue color
//   ongoing: "#2db7f5", // Light Blue color
//   completed: "#87d068", // Green color
//   report_initiated: "#fadb14", // Yellow color
//   report_generated: "#108ee9", // Dark Blue color
//   default: "#d9d9d9", // Default color
// };

const renderInterviewStatus = (record, key) => {
  const status = record[key];
  // const color = InterviewStatusColor[status.toLowerCase()];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Tag
        style={{
          width: 90,
          padding: 2,
          textAlign: "center",
          backgroundColor: status === "Completed" ? "green" : "royalblue",
          color: "white",
          fontWeight: "bold"
        }}
      >
        {status}
      </Tag>
    </div>
  );
};

const renderAction = (record, dataIndex) => {
  let buttonText;
  let actionFunction;
  let buttonDisabled = false;

  if (dataIndex === "upload_report") {
    buttonText = record?.report_uploaded ? "Download" : "";
    actionFunction = record.uploadReport;
    buttonDisabled = record?.report_uploaded ? false : true;
  } else if (dataIndex === "generate_transcript") {
    buttonText = "Generate";
    actionFunction = record.generateTranscript;
    // if (record?.transcript_ok) {
    // } else {
    //   buttonText = "Generated";
    //   actionFunction = () => {};
    //   buttonDisabled = true;
    // }
  } else {
    buttonText = "Action";
    actionFunction = null;
  }
  const handleClick = (e) => {
    e.stopPropagation();
    if (actionFunction) {
      actionFunction(record);
    }
  };
  return !buttonDisabled ? (
    <Button size="small" style={{ cursor: "pointer" }} onClick={handleClick}>
      {buttonText}
    </Button>
  ) : (
    <div>{buttonText}</div>
  );
};

const renderAudio = (record, dataIndex) => {
  const audioUrl = record[dataIndex];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Button
        style={{ marginBottom: 10 }}
        onClick={async () => {
          let resp = await fetch(audioUrl);
          let blob = await resp.blob();
          console.log(record);
          let ri = record?.interview_uuid || "1";
          saveAs(blob, `talentberry-audio-${ri}`);
        }}
      >
        Download File
      </Button>
      <audio controls src={audioUrl}>
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const renderTextCopy = (record, dataIndex) => {
  const dataToCopy = record[dataIndex];
  return (
    <Paragraph
      copyable={{
        text: dataToCopy,
        icon: [
          <CopyOutlined key="copy-icon" />,
          <CheckCircleOutlined key="copied-icon" style={{ color: "green" }} />,
        ],
        tooltips: ["Copy Assessment link.", "Copied Assessment link!"],
      }}
      style={{ display: "inline" }}
    >
      Copy Assessment Link
    </Paragraph>
  );
};
