import React from "react";
import { BarsOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";

const { Text, Title } = Typography;

const DefaultQuestion = () => {
  return (
    <>
      <Flex justify="center" align="center" vertical>
        <BarsOutlined style={{ fontSize: "60px", color: "black" }} />
        <Title level={3}>
          No assessment questions added to this job profile yet
        </Title>
        <Text>Please <a href="https://talentberry.in">contact us</a> if you need any help</Text>
      </Flex>
    </>
  );
};

export default DefaultQuestion;
