/* eslint-disable import/no-unresolved */
import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { FullScreenCentered } from "styles/styles";
import { PATH } from "constants/PATH";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <FullScreenCentered>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate(PATH.Root)}>
                        Back Home
          </Button>
        }
      />
    </FullScreenCentered>
  );
};
export default PageNotFound;
