import axios from "axios";
import { getItemFromLocalStorage } from "../utils/index";
// import store from "redux/store";

let user = null;
user = getItemFromLocalStorage("data");
const axiosInstance = axios.create();

const getOptions = () => {
  user = getItemFromLocalStorage("data");
  let userAuth = {};
  if (user && user.token) {
    userAuth = { headers: { Authorization: `Bearer ${user.token}` } };
  }
  return {
    ...userAuth,
    // ...(method === "POST" ? { "Content-Type": "application/json" } : {}),
  };
};

axiosInstance.interceptors.request.use(
  (config) => {
    // store.dispatch.spinLoading.setLoading(true);
    return config;
  },
  (error) => {
    // store.dispatch.spinLoading.setLoading(false);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // store.dispatch.spinLoading.setLoading(false);
    return response;
  },
  (error) => {
    // store.dispatch.spinLoading.setLoading(false);
    if (
      error.response.status === 403 &&
      error.response.data.message === "Invalid Token"
    ) {
      localStorage.clear();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const axiosGet = async (url) => {
  user = getItemFromLocalStorage("data");
  const res = await axiosInstance.get(url, getOptions());
  return res?.data;
};

const axiosPost = async (url, payload) => {
  const res = await axiosInstance.post(url, payload, getOptions());
  return res?.data;
};

export const api = {
  get: axiosGet,
  post: axiosPost,
};
