/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { Breadcrumb, Flex } from "antd";
import JobProfileDetails from "components/JobProfileDetails";
import NumberOfQuestions from "components/NumberOfQuestions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CenteredSpin from "components/CenteredSpin";
import { PATH } from "constants/PATH";
import { Typography } from "antd";
const JobDetails = () => {
  const { jobDetails, loading } = useSelector((state) => state?.JobModel);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.JobModel.requestGetJobDetails(id);
  }, [id]);

  return (
    <Flex gap="middle" vertical>
      {loading ? (
        <CenteredSpin> </CenteredSpin>
      ) : (
        <>
          <Breadcrumb
            items={[
              { title: "Job Profiles", href: PATH.Jobroles },
              {
                title: (
                  <Typography.Text strong>Job Profile Details</Typography.Text>
                ),
                href: window.location.href,
              },
            ]}
          ></Breadcrumb>
          <JobProfileDetails jobId={id} jobDetails={jobDetails} />
          <NumberOfQuestions jobId={id} jobDetails={jobDetails} />
        </>
      )}
    </Flex>
  );
};

export default JobDetails;
