import rootUrl from "./rootUrl";
export const LOGIN_URL = `${rootUrl}/auth/login`;
export const CONTACT_SALES_URL = `${rootUrl}/api/contact-sales`;
export const CREATE_COMPANY_URL = `${rootUrl}/api/company-create`;
export const LIST_COMPANY_URL = `${rootUrl}/api/company-list`;
export const DASHBOARD_STATS = `${rootUrl}/api/dashboard-stats`;
export const COMPANY_DETAILS_URL = `${rootUrl}/api/company-detail`;
export const UPDATE_COMPANY_URL = `${rootUrl}/api/company-update`;
export const ACTIVATE_COMPANY_STATUS_URL = `${rootUrl}/api/company-status-change`;
export const CREATE_JOB_URL = `${rootUrl}/api/job-create`;
export const LIST_JOB_URL = `${rootUrl}/api/job-list`;
export const JOB_DELETE_URL = `${rootUrl}/api/job-delete`;
export const JOB_DETAILS_URL = `${rootUrl}/api/job-detail`;
export const JOB_UPDATE_URL = `${rootUrl}/api/job-update`;
export const JOB_EMAIL_TEMPLATE_UPDATE_URL = `${rootUrl}/api/job-email-template-update`;
export const ACTIVATE_JOB_STATUS_URL = `${rootUrl}/api/job-update`;
export const CREATE_QUESTION_URL = `${rootUrl}/api/question-create`;
export const LIST_QUESTION_URL = `${rootUrl}/api/question-list`;
export const QUESTION_DETAILS_URL = `${rootUrl}/api/question-detail`;
export const QUESTION_DELETE_URL = `${rootUrl}/api/question-delete`;
export const QUESTION_UPDATE_URL = `${rootUrl}/api/question-update`;
export const VERIFY_QUESTION_STATUS_URL = `${rootUrl}/api/question-verify-status-change`;
export const CREATE_CANDIDATE_URL = `${rootUrl}/api/candidate-create`;
export const CREATE_CANDIDATE_BULK = `${rootUrl}/api/candidate-bulk-create`;
export const UPDATE_CANDIDATE_URL = `${rootUrl}/api/candidate-update`;
export const LIST_CANDIDATE_URL = `${rootUrl}/api/candidate-list`;
export const CREATE_INTERVIEW_URL = `${rootUrl}/api/interview-create`;
export const LIST_INTERVIEW_URL = `${rootUrl}/api/interview-list`;
export const INTERVIEW_DELETE_URL = `${rootUrl}/api/interview-delete`;
export const INTERVIEW_DETAILS_URL = `${rootUrl}/api/interview-detail`;
export const INTERVIEW_TRANSCRIPT_GENERATE = `${rootUrl}/api/interview-transcript-generate`;
export const INTERVIEW_REPORT_UPLOAD = `${rootUrl}/api/interview-report-upload`;
export const INTERVIEW_BULK_CREATE_URL = `${rootUrl}/api/interview-bulk-create`;
export const INTERVIEW_SEND_MAIL_URL = `${rootUrl}/api/interview-send-email`;
export const INTERVIEW_MAIL_TEMPLATE_COPY_URL = `${rootUrl}/api/interview-mail-template-copy`;
export const COMPANY_GET_PROFILE_INFO = `${rootUrl}/api/company-get-profile-info`;
export const SUPERADMIN_GET_INTERVIEW_DATA_FOR_COMPANY_URL = `${rootUrl}/api/sa-get-interview-data-for-company`;
export const SUPERADMIN_UPLOAD_INTERVIEW_REPORT_URL = `${rootUrl}/api/sa-upload-interview-report`;
export const SUPERADMIN_DELETE_INTERVIEW_REPORT_URL = `${rootUrl}/api/sa-delete-interview-report`;
// ========
export const ROLEPLAY_CHECK_COMPANY_EXISTS = `${rootUrl}/api/roleplay-check-company-exists`;
export const ROLEPLAY_CREATE_SCENARIO = `${rootUrl}/api/roleplay-create-scenario`;
export const ROLEPLAY_GENERATE_SCENARIOS = `${rootUrl}/api/roleplay-generate-scenarios`;
export const ROLEPLAY_GET_SCENARIOS_FOR_COMPANY = `${rootUrl}/api/roleplay-get-scenarios-for-company`;
export const ROLEPLAY_GET_ALL_INSTANCES = `${rootUrl}/api/roleplay-get-all-instances`;
export const ROLEPLAY_ASSIGN_SCENARIO = `${rootUrl}/api/roleplay-assign-scenario`;
export const ROLEPLAY_GET_LINK = `${rootUrl}/api/roleplay-get-link`;
export const ROLEPLAY_SEND_MAIL = `${rootUrl}/api/roleplay-send-mail`;
export const ROLEPLAY_GET_ALL_RESULTS = `${rootUrl}/api/roleplay-get-all-results`;
export const ROLEPLAY_VIEW_RESULT = `${rootUrl}/api/roleplay-view-result`;
export const ROLEPLAY_GET_COMPANY_INFO = `${rootUrl}/api/roleplay-get-company-info`;
export const ROLEPLAY_GET_INSTANCE_RESULT = `${rootUrl}/api/roleplay-get-instance-result`;

// ======

export const RA_MERGE_GET_TARGETS = `${rootUrl}/api/ra-merge-get-targets`;
export const RA_MERGE_CREATE = `${rootUrl}/api/ra-merge-create`;