/* eslint-disable import/no-unresolved */
import CommonLayout from "components/CommonLayout";
import debugLog from "../env/debugLog";
import { getItemFromLocalStorage } from "utils";

const { Navigate } = require("react-router-dom");
const PrivateRoutes = () => {
  const user = getItemFromLocalStorage("data");
  debugLog("privateRoute getItemFromLocalStorage user", user);
  let auth = { token: user ? user.token : null };
  return auth.token ? <CommonLayout /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
