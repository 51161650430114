/* eslint-disable import/no-unresolved */
import { SearchOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Card,
  Modal,
  Table,
  //   Button,
  Tag,
  Typography,
} from "antd";
import { api } from "axiosRequest";
import FileUpload from "common/FileUpload";
import CenteredSpin from "components/CenteredSpin";
import { PATH } from "constants/PATH";
import {
  LIST_COMPANY_URL,
  SUPERADMIN_DELETE_INTERVIEW_REPORT_URL,
  SUPERADMIN_GET_INTERVIEW_DATA_FOR_COMPANY_URL,
  SUPERADMIN_UPLOAD_INTERVIEW_REPORT_URL,
} from "constants/api";
import { useEffect, useState } from "react";

const UploadModal = ({
  isOpen = false,
  targetInterview = null,
  onClose = () => {},
  companyId = null,
  companyName = null,
  onRefresh = () => {},
}) => {
  const [interviewPdf, setInterviewPdf] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isOpen) setInterviewPdf(null);
  }, [isOpen]);

  const uploadFile = async () => {
    setIsUploading(true);
    const fl = new FileReader();
    fl.onload = async (e) => {
      const fileStr = e.target.result;
      if (
        interviewPdf &&
        companyId &&
        targetInterview &&
        targetInterview.id &&
        fileStr
      ) {
        try {
          const res = await api.post(SUPERADMIN_UPLOAD_INTERVIEW_REPORT_URL, {
            interview_pdf_base64: fileStr.split("base64,")[1],
            company_id: companyId,
            interview_id: targetInterview.id,
          });
          setIsUploading(false);
          if (res) {
            alert("Uploaded Successfully");
            onClose();
            onRefresh();
          } else {
            onClose();
          }
        } catch (e) {
          alert(e.toString());
          setIsUploading(false);
          onClose();
        }
      }
    };
    fl.readAsDataURL(interviewPdf);
  };
  return (
    <Modal
      open={isOpen}
      onCancel={isUploading ? null : onClose}
      onOk={uploadFile}
      style={{ padding: 10 }}
    >
      <div style={{ margin: 20, width: 400 }}>
        {isUploading ? (
          <CenteredSpin />
        ) : (
          <>
            <div>
              <Typography.Text>
                Uploading Report for Assessment ID {targetInterview?.id}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Company: {companyName}</Typography.Text>
            </div>
            <FileUpload
              accept=".pdf"
              onFileUpload={(e) => {
                setInterviewPdf(e);
                console.log(e);
              }}
            />
            {interviewPdf ? (
              <Typography.Text>
                Selected File :{" "}
                <span style={{ fontWeight: "bold" }}>{interviewPdf?.name}</span>
              </Typography.Text>
            ) : null}
          </>
        )}
      </div>
    </Modal>
  );
};

const UploadReport = () => {
  const [dcx, setDcx] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [targetInterview, setTargetInterview] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const refresh = () => setDcx(dcx + 1);
  const onUploadReport = async (currentInterview) => {
    setTargetInterview(currentInterview);
    setUploadModalOpen(true);
  };
  const onDeleteReport = async (currentInterview) => {
    setTargetInterview(currentInterview);
    const chk = confirm(
      `Are you sure you want to delete the report for assessment ID ${currentInterview.id} for company ${selectedCompanyName}? This action cannot be reversed`
    );
    if (chk) {
      const res = api.post(SUPERADMIN_DELETE_INTERVIEW_REPORT_URL, {
        company_id: selectedCompanyId,
        interview_id: currentInterview.id,
      });
      if (res) {
        alert("Deleted successfully");
      }
    }
  };

  const fmtx = (interviewData) => {
    return interviewData
      ? interviewData.map((currentInterview) => ({
          ...currentInterview,
          report_uploaded: (
            <Tag color={currentInterview.report_uploaded ? "green" : "red"}>
              {currentInterview.report_uploaded ? "Yes" : "No"}
            </Tag>
          ),
          actions: (
            <Button.Group>
              <Button
                disabled={currentInterview?.report_uploaded}
                onClick={() => {
                  onUploadReport(currentInterview);
                }}
              >
                Upload Report
              </Button>
              <Button
                disabled={!currentInterview?.report_uploaded}
                onClick={async () => {
                  await onDeleteReport(currentInterview);
                }}
              >
                Delete Report
              </Button>
            </Button.Group>
          ),
        }))
      : [];
  };

  useEffect(() => {
    api
      .get(LIST_COMPANY_URL)
      .then(async (r) => {
        if (r) {
          console.log(r);
          setCompanyList(r);
        } else {
          alert(`ERROR : ${r}`);
        }
      })
      .catch((e) => {
        alert(`ERROR : ${e.toString()}`);
      });
  }, []);

  useEffect(() => {
    if (selectedCompanyName && selectedCompanyId) {
      api
        .post(SUPERADMIN_GET_INTERVIEW_DATA_FOR_COMPANY_URL, {
          company_id: selectedCompanyId,
        })
        .then((r) => {
          if (r) {
            setTableData(r);
          }
        })
        .catch((e) => {
          alert(e.toString());
        });
    }
  }, [selectedCompanyId, dcx]);

  return (
    <>
      <UploadModal
        companyName={selectedCompanyName}
        companyId={selectedCompanyId}
        targetInterview={targetInterview}
        isOpen={uploadModalOpen}
        onClose={() => {
          setUploadModalOpen(false);
        }}
        onRefresh={refresh}
      />
      <Breadcrumb
        items={[
          { title: "Dashboard", href: PATH.Dashboard },
          {
            title: <Typography.Text strong>Upload Reports</Typography.Text>,
            href: PATH.UploadReport,
          },
        ]}
      ></Breadcrumb>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Typography.Title level={4}>Upload Report</Typography.Title>
          <Typography.Text style={{ marginTop: 10, marginBottom: 20 }}>
            Select the appropriate company and candidate
          </Typography.Text>
        </div>
        <AutoComplete
          suffixIcon={<SearchOutlined />}
          options={companyList.map((x) => ({
            value: x.company_name,
            label: x.company_name,
          }))}
          defaultValue={"Select Company"}
          style={{ marginTop: 10, marginBottom: 10, minWidth: 400 }}
          onChange={(e) => {
            setSelectedCompanyName(e);
            const cmpid = companyList
              ?.filter((x) => x.company_name === e)
              ?.map((x) => x.id)
              ?.pop();
            setSelectedCompanyId(cmpid);
          }}
        ></AutoComplete>
        {selectedCompanyName ? (
          <>
            {" "}
            <Typography.Title
              level={4}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {selectedCompanyName}
              {selectedCompanyName ? (
                <span style={{ fontSize: 12, marginLeft: 10 }}>
                  (Company ID : {selectedCompanyId})
                </span>
              ) : null}
            </Typography.Title>
            <Table
              style={{ minWidth: 1000, height: "auto" }}
              dataSource={fmtx(tableData)}
              columns={[
                {
                  title: "Date",
                  dataIndex: "created_at",
                  key: "interview_date",
                  //   sorter: (a, b) =>
                  //     new Date(b || 0).getTime() - new Date(a || 0).getTime(),
                },
                {
                  title: "Assessment ID",
                  dataIndex: "id",
                  key: "Assessment ID",
                  sorter: (a, b) =>
                    parseInt(a?.id || "0") - parseInt(b?.id || "0"),
                },
                {
                  title: "Candidate ID",
                  dataIndex: "user_id",
                  key: "candidate_id",
                },
                {
                  title: "Candidate Name",
                  dataIndex: "candidate_name",
                  key: "candidate_name",
                },
                {
                  title: "Job Title",
                  dataIndex: "job_title",
                  key: "Job Title",
                },
                { title: "Job ID", dataIndex: "job_id", key: "Job ID" },
                {
                  title: "Report Uploaded",
                  dataIndex: "report_uploaded",
                  key: "Report Uploaded",
                },
                {
                  title: "Actions",
                  dataIndex: "actions",
                  key: "Actions",
                  width: 350,
                },
              ]}
            ></Table>
          </>
        ) : null}
      </Card>
    </>
  );
};

export default UploadReport;
