/* eslint-disable no-unused-vars */
import {
  CopyOutlined,
  InfoCircleOutlined,
  SendOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Input,
  Table,
  Tag,
  Typography,
} from "antd";
import { api } from "../../axiosRequest";
import {
  ROLEPLAY_ASSIGN_SCENARIO,
  ROLEPLAY_GET_ALL_INSTANCES,
  ROLEPLAY_GET_LINK,
  ROLEPLAY_GET_SCENARIOS_FOR_COMPANY,
  ROLEPLAY_SEND_MAIL,
} from "../../constants/api";
// import { ROLEPLAY_ASSIGN_SCENARIO } from "constants/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CenteredSpin from "../../components/CenteredSpin";

const fakeOpts = [
  {
    scenario_text: "Scenario 1",
    scenario_uuid: "123",
    value: "Scenario 1",
  },
  {
    scenario_text: "Scenario 2",
    scenario_uuid: "456",
    value: "Scenario 2",
  },
  {
    scenario_text: "Scenario 3",
    scenario_uuid: "789",
    value: "Scenario 3",
  },
].map((x) => ({ value: x.value }));

const instanceTableColumns = [
  {
    title: "ID",
    dataIndex: "instance_uuid",
    key: "instance_uuid",
    width: 10,
  },
  {
    title: "Candidate",
    dataIndex: "full_name",
    key: "full_name",
  },
  //   {
  //     title: "Candidate ID",
  //     dataIndex: "candidate_id",
  //     key: "candidate",
  //   },
  {
    title: "Scenario",
    dataIndex: "scenario_text",
    key: "scenario",
  },
  //   {
  //     title: "Category",
  //     dataIndex: "category",
  //     key: "category",
  //   },

  {
    title: "Status",
    dataIndex: "roleplay_status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "date",
    sorter: (x, y) => new Date(x.date).getTime() - new Date(y.date).getTime(),
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
  },
];

const fakeInstanceData = Array(50)
  .fill(0)
  .slice(1)
  .map((_, n) => ({
    key: n,
    id: n,
    scenario: n,
    category: n,
    status: "pending",
    date: n % 2 ? "2021-01-01" : "2024-04-04",
    candidate: "Rahul Kumar",
    actions: (
      <div>
        <InfoCircleOutlined style={{ marginRight: 10, color: "blue" }} />
        <CopyOutlined style={{ marginRight: 10, color: "blue" }} />
        <SendOutlined style={{ marginRight: 10, color: "blue" }} />
      </div>
    ),
    result: <Button>Download</Button>,
  }));

const AssignRoleplay = () => {
  const navigate = useNavigate();
  const [instanceData, setInstanceData] = useState(fakeInstanceData);
  const [assignMode, setAssignMode] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState({});
  const [searchText, setSearchText] = useState(""); // Added state for search text
  const [filteredData, setFilteredData] = useState([]); // Added state for filtered data
  const [selectedScenario, setSelectedScenario] = useState("");
  const dataSource = candidateList.map((x) => ({
    key: x.id,
    id: x.id,
    name: `${x.first_name} ${x.last_name}`,
    email: x.email,
  }));

  const onCandidateSelect = (key, isSelected) => {
    setSelectedCandidates({ ...selectedCandidates, [key]: isSelected });
  };

  const handleSearch = (vx) => {
    let value = vx.target.value;
    setSearchText(value);
    if (!value) {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter(
        (candidate) =>
          candidate?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
          candidate?.email?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [scenariosList, setScenariosList] = useState(fakeOpts);
  const handleAssign = async () => {
    // gather the information
    console.log("selectedCandidates", selectedCandidates);
    const assignedCandidates = Object.entries(selectedCandidates)
      .filter(([_, isSelected]) => isSelected)
      .map(([key]) =>
        candidateList.find((candidate) => candidate.id === parseInt(key))
      )
      .filter((x) => x)
      .map((candidate) => candidate.id);

    const selectedScenarioObj = scenariosList.find(
      (scenario) => scenario.value === selectedScenario
    );
    const selectedScenarioUuid = selectedScenarioObj?.scenario_uuid;
    console.log("Selected candidates:", assignedCandidates);
    console.log("Selected scenario:", selectedScenario);
    console.log("Selected scenario UUID:", selectedScenarioUuid);

    const robj = {
      candidate_list: assignedCandidates,
      scenario_text: selectedScenario,
      scenario_uuid: selectedScenarioUuid,
    };

    try {
      await api.post(ROLEPLAY_ASSIGN_SCENARIO, robj);
      setAssignMode(false);
    } catch (e) {
      console.log(e);
    } finally {
      console.log(robj);
    }
  };

  useEffect(() => {
    if (!assignMode) {
      const fetchInstances = async () => {
        const data = await api.get(ROLEPLAY_GET_ALL_INSTANCES);
        setInstanceData(data);
      };
      setIsLoading(true);
      fetchInstances().finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      const fetchScenarios = async () => {
        try {
          const data = await api.get(ROLEPLAY_GET_SCENARIOS_FOR_COMPANY);
          const scenarioData = data.scenarios.map((x) => ({
            value: x.scenario_text,
            scenario_uuid: x.scenario_uuid, // Store scenario_uuid as well
          }));
          const candidateList = data.candidates;
          console.log(data);
          setScenariosList(scenarioData);
          setSelectedScenario(scenarioData[0].value); // Update to use .value
          setCandidateList(candidateList);
        } catch (error) {
          console.error("Error fetching scenarios:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchScenarios();
    }
  }, [assignMode]);

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <CenteredSpin />;
  }

  if (assignMode) {
    return (
      <Card
        title={"Assign Roleplay to Candidate"}
        style={{ width: "80vw", minHeight: 400 }}
      >
        <div style={{ marginTop: 20 }}>
          <Typography.Text>Select a Scenario</Typography.Text>
        </div>
        <div>
          <AutoComplete
            style={{ width: "100%" }}
            defaultValue={"Scenario 1"}
            value={selectedScenario}
            onChange={(e) => setSelectedScenario(e)}
            options={scenariosList}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Typography.Text>Select Candidates</Typography.Text>
        </div>

        <div>
          <Input
            placeholder="Search candidates"
            value={searchText}
            onChange={handleSearch}
            style={{ marginBottom: 10 }}
          />

          <Table
            dataSource={searchText ? filteredData : dataSource}
            pagination={false}
          >
            <Table.Column
              title="Select"
              dataIndex="key"
              key="selected"
              width={100}
              render={(key) => (
                <Checkbox
                  checked={selectedCandidates[key]}
                  onChange={(e) => onCandidateSelect(key, e.target.checked)}
                />
              )}
            />
            <Table.Column title="ID" dataIndex="id" key="id" />
            <Table.Column title="Name" dataIndex="name" key="name" />
            <Table.Column title="Email" dataIndex="email" key="email" />
          </Table>
        </div>

        <div style={{ marginTop: 10 }}>
          <Button
            style={{ marginRight: 10, backgroundColor: "blue", color: "white" }}
            onClick={handleAssign}
            id="assignnow"
          >
            Assign
          </Button>
          <Button style={{ marginRight: 10 }}>Cancel</Button>
        </div>
      </Card>
    );
  } else
    return (
      <>
        <div
          style={{
            minWidth: 800,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography.Title level={3}>Assign Roleplay</Typography.Title>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              icon={<UserAddOutlined />}
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={() => setAssignMode(true)}
            >
              Assign
            </Button>
          </div>
          <Table
            columns={instanceTableColumns}
            dataSource={instanceData
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              .map((x) => ({
                ...x,
                result: (
                  <Button
                    disabled={x.roleplay_status !== "completed"}
                    onClick={() => {
                      navigate("/view-roleplay/" + x.instance_uuid);
                    }}
                  >
                    View
                  </Button>
                ),
                created_at: new Date(x.created_at).toLocaleDateString(),
                roleplay_status:
                  x.roleplay_status === "completed" ? (
                    <Tag color="success">Completed</Tag>
                  ) : (
                    <Tag color="blue">Pending</Tag>
                  ),
                actions: (
                  <div style={{ minWidth: 100 }}>
                    <InfoCircleOutlined
                      style={{ marginRight: 10, color: "blue", fontSize: 15 }}
                    />
                    <CopyOutlined
                      style={{ marginRight: 10, color: "blue", fontSize: 15 }}
                      onClick={async () => {
                        console.log(x.instance_uuid);
                        const res = await api.get(
                          ROLEPLAY_GET_LINK + "/" + x.instance_uuid
                        );
                        navigator.clipboard.writeText(res);
                        alert("Link copied to clipboard");
                      }}
                    />
                    <SendOutlined
                      onClick={async () => {
                        await api.get(
                          ROLEPLAY_SEND_MAIL + "/" + x.instance_uuid
                        );
                        alert("Mail sent!");
                      }}
                      style={{ marginRight: 10, color: "blue", fontSize: 15 }}
                    />
                  </div>
                ),
              }))}
            style={{ width: "100%" }}
          />
        </div>
      </>
    );
};

export default AssignRoleplay;
