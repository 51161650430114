/* eslint-disable import/no-unresolved */
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Typography,
} from "antd";
import { api } from "axiosRequest";
import CandidateResponseList from "components/CandidateResponse";
import CenteredSpin from "components/CenteredSpin";
import {
  INTERVIEW_DELETE_URL,
  INTERVIEW_TRANSCRIPT_GENERATE,
} from "constants/api";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCapitalizeFirstLetter } from "utils";
import { PATH } from "constants/PATH";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const InterviewDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { interviewDetails, loading } = useSelector(
    (state) => state?.InterviewModel
  );
  const { id } = useParams();
  const [gtlock, setgtlock] = useState(false);
  const exportDocx = async () => {
    const interview_uuid = interviewDetails?.interview_uuid;
    const candidate_responses = interviewDetails?.candidate_responses;
    if (
      interview_uuid &&
      candidate_responses &&
      Array.isArray(candidate_responses)
    ) {
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: candidate_responses.map(
                  (x) =>
                    new TextRun(
                      `Question ${x.sequence}: ${x.question}\nAnswer: ${x.ai_generated_transcript}\n\n`
                    )
                ),
              }),
            ],
          },
        ],
      });
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `talentberry-${interview_uuid}.docx`);
      });
    }
  };
  const genTranscript = async (reload = false) => {
    let iu = interviewDetails.interview_uuid;
    if (!gtlock) setgtlock(true);
    try {
      const res = await api.get(`${INTERVIEW_TRANSCRIPT_GENERATE}/${iu}`);
      if (res) {
        alert("Transcript generated successfully");
        reload ? window.location.reload() : null;
      }
    } catch (e) {
      alert("ERROR: Could not generate transcript");
      console.log(e);
    } finally {
      setgtlock(false);
    }
  };

  useEffect(() => {
    dispatch.InterviewModel.requestGetInterviewDetails(id);
  }, [id]);

  const items = [
    { label: "ID", key: "id" },
    {
      label: "Candidate Name",
      key: "user_profile",
      render: () =>
        getCapitalizeFirstLetter(
          `${interviewDetails?.user_profile?.first_name} ${interviewDetails?.user_profile?.last_name}`
        ),
    },
    {
      label: "Candidate Email",
      key: "user_profile",
      render: () => `${interviewDetails?.user_profile?.email}`,
    },
    {
      label: "Job Title",
      key: "job",
      render: () => `${interviewDetails?.job?.title}`,
    },
    {
      label: "Status",
      key: "interview_status",
    },
    {
      label: "Joined At",
      key: "candidate_joined_at",
      render: () =>
        interviewDetails?.interview_status === "Pending"
          ? "Not Joined Yet"
          : interviewDetails?.candidate_joined_at,
    },
    {
      label: "Completed At",
      key: "candidate_completed_at",
    },
    {
      label: "Report Uploaded",
      key: "report_uploaded",
      render: () => (interviewDetails?.report_uploaded ? "Yes" : "Not Yet"),
    },
    {
      label: "Actions",
      key: "generate_transcript",
      render: () => (
        <Button.Group>
          <Button onClick={genTranscript} disabled={gtlock}>
            {gtlock ? "Generating.." : "Generate AI Transcript"}
          </Button>
          <Button
            onClick={async () => {
              await genTranscript(false);
              await exportDocx();
            }}
            disabled={gtlock}
          >
            Export as Word Document
          </Button>
          <Button
            onClick={async () => {
              window.location.assign(interviewDetails?.interview_report);
            }}
            disabled={gtlock || !interviewDetails?.report_uploaded}
          >
            Download Assessment Report
          </Button>
          <Button
            onClick={async () => {
              const chk = confirm(
                "Are you sure you want to delete this interview? This is irreversible and cannot be undone."
              );
              if (chk) {
                const res = await api.post(INTERVIEW_DELETE_URL, {
                  interview_uuid: interviewDetails?.interview_uuid,
                });
                if (res) {
                  alert("Deleted successfully");
                  navigate(PATH.Interviews);
                }
              }
            }}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Delete
          </Button>
        </Button.Group>
      ),
    },
  ];

  return loading ? (
    <CenteredSpin />
  ) : (
    <>
      <Breadcrumb
        items={[
          { title: "Assessments", href: PATH.Interviews },
          {
            title: <Typography.Text strong>Assessment Details</Typography.Text>,
            href: window.location.pathname,
          },
        ]}
      ></Breadcrumb>
      <Row gutter={16} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Card>
            <Descriptions
              title={<Title level={5}>Assessment Details</Title>}
              layout="vertical"
              bordered
            >
              {items.map((item) => (
                <Descriptions.Item key={item.label} label={item.label}>
                  {item.render
                    ? item.render()
                    : item.key === "candidate_joined_at" ||
                      item.key === "candidate_completed_at"
                    ? new Date(interviewDetails[item.key]).toString()
                    : interviewDetails?.[item.key]}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Card>
            <CandidateResponseList
              candidateResponse={interviewDetails?.candidate_responses}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InterviewDetails;
