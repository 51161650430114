/* eslint-disable import/no-unresolved */

import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useEffect, useState } from "react";
import { createTableColumns } from "utils/tableUtils";

const ScreenshotPreview = ({ screenshot }) => {
  if (!screenshot) {
    return <div>Image Not Available</div>;
  }
  let s2 = screenshot;
  return (
    <div onClick={() => window.open(s2, "_blank")}>
      <img width={300} height={150} src={s2}></img>
    </div>
  );
};

const CandidateResponseList = ({ candidateResponse }) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [responseList, setResponseList] = useState([]);

  const getColumns = () => {
    const columns = Admin?.interviewResponseList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  };

  useEffect(() => {
    getColumns();
  }, []);

  useEffect(() => {
    if (candidateResponse?.length > 0) {
      setResponseList(
        candidateResponse.map((x) => ({
          ...x,
          screenshot: <ScreenshotPreview screenshot={x.screenshot} />,
        }))
      );
    }
  }, [candidateResponse]);

  return (
    <div>
      <CustomTable columns={tableColumns} data={responseList} />
    </div>
  );
};

export default CandidateResponseList;
