import React from "react";
import { Footer } from "antd/es/layout/layout";
import appVersion from "../../constants/appVersion";

const CustomFooter = () => {
  return (
    <>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        © 2024 Skynetic Ventures LLP. All rights reserved. App Version 0.{appVersion}
      </Footer>
    </>
  );
};

export default CustomFooter;
