/* eslint-disable import/no-unresolved */

import {
  AreaChartOutlined,
  CalendarOutlined,
  ContactsOutlined,
  FileAddOutlined,
  FormOutlined,
  PieChartOutlined,
  RedEnvelopeOutlined,
  SolutionOutlined,
  TeamOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import Link from "antd/es/typography/Link";
import { PATH } from "constants/PATH";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isSuperAdmin } from "utils";

const pathMap = {
  [PATH.Root]: PATH.Dashboard,
  [PATH.InterviewDetails]: PATH.Interviews,
  [PATH.AddInterview]: PATH.Interviews,
  [PATH.BulkAddInterviews]: PATH.Interviews,
  [PATH.JobDetails]: PATH.Jobroles,
  [PATH.AddJob]: PATH.Jobroles,
  [PATH.AddCandidate]: PATH.Candidates,
};

let pathkeys = Object.keys(pathMap);

const SideMenu = ({ items = [] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathResolver = (pathname) => {
    for (let z of pathkeys) {
      if (z !== PATH.Root && pathname.indexOf(z) !== -1) return pathMap[z];
    }
    return pathMap[pathname] || pathname;
  };

  const [menuState, setMenuState] = useState("");
  React.useEffect(() => {
    setMenuState(pathResolver(pathname));
  }, [pathname]);

  return (
    <Menu
      onClick={({ key }) => {
        navigate(key);
      }}
      theme="dark"
      selectedKeys={[menuState]}
      mode="inline"
    >
      {items.map((item) => (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const getItemsBasedOnRole = () => {
    if (isSuperAdmin()) {
      return [
        getItem("Dashboard", PATH.Dashboard, <PieChartOutlined />),
        getItem("Companies", PATH.Companies, <TeamOutlined />),
        getItem("Upload Reports", PATH.UploadReport, <RedEnvelopeOutlined />),
        getItem("Create Reports", PATH.CreateReport, <FileAddOutlined />),
      ];
    } else
      return [
        getItem("Dashboard", PATH.Dashboard, <PieChartOutlined />),
        getItem("Profiles", PATH.Jobroles, <SolutionOutlined />),
        getItem("Assessments", PATH.Interviews, <CalendarOutlined />),
        getItem("Candidates", PATH.Candidates, <ContactsOutlined />),
        getItem("Create Roleplay", PATH.CreateRoleplay, <FormOutlined />),
        getItem("Assign Roleplay", PATH.AssignRoleplay, <AreaChartOutlined />),
        getItem("Create Link", PATH.CreateLink, <LinkOutlined />),
        // getItem("Roleplay Results", PATH.RoleplayResults, <AreaChartOutlined />),
      ];
  };

  const items = getItemsBasedOnRole();

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Link href={PATH.Root}>
          <h6
            style={{
              color: "white",
              fontSize: "20px",
              textAlign: "center",
              cursor: "pointer",
              width: collapsed ? 80 : "auto",
            }}
          >
            {collapsed ? "TB" : "TalentBerry"}
          </h6>
        </Link>
        <SideMenu items={items} />
      </Sider>
    </>
  );
};

export default React.memo(SideBar);
