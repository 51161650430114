/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useEffect, useState } from "react";
import TableOptions from "common/TableOptions";
import { PATH } from "constants/PATH";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTableColumns } from "utils/tableUtils";
import { FlexBoxVertical } from "styles/styles";
import { Breadcrumb, Spin } from "antd";
import { Modal } from "antd";
import { Input } from "antd";
import { Typography } from "antd";
import { api } from "axiosRequest";
import { UPDATE_CANDIDATE_URL } from "constants/api";

const Cxmodal = () => {
  const CandidateModelState = useSelector((x) => x.CandidateModel);
  const cmstate = CandidateModelState?.editCandidateModalState;
  const isOpen = cmstate?.isOpen;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModal = () =>
    dispatch?.CandidateModel?.toggleEditCandidateModalState({
      isOpen: false,
    });
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  useEffect(() => {
    if (isOpen) {
      setState({
        first_name: cmstate?.first_name,
        last_name: cmstate?.last_name,
        email: cmstate?.email,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      okText="Save"
      title="Edit Candidate"
      onOk={async () => {
        let payload = { ...state, candidate_id: cmstate?.id };
        await api.post(UPDATE_CANDIDATE_URL, payload);
        closeModal();
        navigate(0);
      }}
      onCancel={closeModal}
    >
      <Input
        addonBefore={<Typography.Text>First Name</Typography.Text>}
        style={{ margin: 10 }}
        value={state?.first_name}
        onChange={(e) => setState({ ...state, first_name: e.target.value })}
      ></Input>
      <Input
        addonBefore={<Typography.Text>Last Name</Typography.Text>}
        style={{ margin: 10 }}
        value={state?.last_name}
        onChange={(e) => setState({ ...state, last_name: e.target.value })}
      ></Input>
      <Input
        addonBefore={<Typography.Text>Candidate Email</Typography.Text>}
        style={{ margin: 10 }}
        value={state?.email}
        onChange={(e) => setState({ ...state, email: e.target.value })}
      ></Input>
    </Modal>
  );
};

const Candidates = () => {
  const dispatch = useDispatch();
  const { candidates } = useSelector((state) => state?.CandidateModel);
  const [tableColumns, setTableColumns] = useState([]);
  const [candidateList, setCandidateList] = useState([]);

  const navigate = useNavigate();

  const getColumns = () => {
    const columns = Admin?.candidateList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isLoading) return;
    else setIsLoading(true);
    dispatch.CandidateModel.requestGetCandidateList()
      .then(
        () => setIsLoading(false),
        () => setIsLoading(false)
      )
      .catch(console.log);
    getColumns();
  }, []);

  useEffect(() => {
    if (candidates?.length > 0) {
      setCandidateList(candidates);
    }
  }, [candidates]);

  return (
    <FlexBoxVertical>
      <Cxmodal />
      <Breadcrumb
        items={[{ title: "Candidates", path: PATH.Candidates }]}
      ></Breadcrumb>
      <TableOptions
        title="Candidates"
        btnText="Add Candidate"
        path="/add-candidate"
      />
      {isLoading ? (
        <Spin />
      ) : (
        <CustomTable
          data={candidateList?.sort((a, b) => b.id - a.id)}
          columns={tableColumns}
        />
      )}
    </FlexBoxVertical>
  );
};

export default Candidates;
