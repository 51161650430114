/* eslint-disable import/no-unresolved */
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRoutes from "./routes";

const MainComponent = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MainComponent />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
