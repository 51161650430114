/* eslint-disable import/no-unresolved */
import { Typography } from "antd";
import Image from "common/Image";
import Login from "components/Login";
import landingImage from "../../assets/Images/a.jpg";

const Auth = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around ",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Text style={{marginBottom: 10, width: "80%", textAlign: "left" }}>
          Build nuanced customer personas and automate engaging discussions with{" "}
          <span style={{ fontWeight: "bold", color: "blue" }}>TalentBerry</span>
        </Typography.Text>
        <Image
          src={landingImage}
          width="80%"
          height="60%"
          alt="Talentberry"
          style={{ borderRadius: 20 }}
        />
      </div>
      <Login />
    </div>
  );
};

export default Auth;
