/* eslint-disable import/no-unresolved */

import { LogoutOutlined, SmileTwoTone, UserOutlined } from "@ant-design/icons";
import { Dropdown, Space, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import Modal from "common/Modal";
import { PATH } from "constants/PATH";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCapitalizeFirstLetter, getItemFromLocalStorage } from "utils";
const Navbar = () => {
  const userData = getItemFromLocalStorage("data");

  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false)


  const userName = userData?.profile?.first_name && userData?.profile?.last_name
    ? getCapitalizeFirstLetter(`${userData?.profile?.first_name} ${userData?.profile?.last_name}`)
    : "";

  const handleLogout = () => {
    setOpenLogoutModal(!openLogoutModal)
  }

  const handleSubmitLogout = () => {
    localStorage.clear();
    navigate(PATH.Login)
  }

  const handleCancelLogout = () => {
    setOpenLogoutModal(!openLogoutModal);
  }
  const handleProfile = () => {
    navigate(PATH.Profile)
  }

  const items = [
    {
      key: "1",
      label: (
        <div role="button"
          tabIndex={0}
          href={PATH.Profile} style={{ fontSize: "17px" }} onClick={handleProfile}>
          Profile
        </div>
      ),
      icon: <SmileTwoTone />,
    },
    {
      key: "4",
      danger: true,
      label: (
        <div style={{ fontSize: "17px" }} onClick={handleLogout} role="button"
          tabIndex={0}
          aria-label="Logout">
          Logout
        </div>
      ),
      icon: <LogoutOutlined />
    },
  ];
  return (
    <>
      <Header
        style={{
          padding: "10px",
          background: theme.useToken().token.colorBgContainer,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: "17px", marginRight: "20px" }}>
            {userName} </span>
        </div>

        <Dropdown
          menu={{
            items,
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <Space size="large" style={{ paddingRight: "20px" }}>
              <UserOutlined style={{ fontSize: "25px" }} />
            </Space>
          </div>
        </Dropdown>
      </Header>
      {openLogoutModal &&
        <Modal
          title="Are you sure want to Logout?"
          okText="Logout"
          cancelText="Cancel"
          open={openLogoutModal}
          handleSubmit={handleSubmitLogout}
          onCancel={handleCancelLogout}
          centered={true}
          closeIcon={true}
          width={500} />
      }
    </>
  )
}

export default Navbar
