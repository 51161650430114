/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { Breadcrumb, Button, Card, Divider, Table, Typography } from "antd";
import FileUpload from "common/FileUpload";
import MultipleCandidatesForm from "components/MultipleCandidateForm";
import { PATH } from "constants/PATH";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexBox } from "styles/styles";

const { Title, Text } = Typography;

const candidateBulkCreationLink =
  "https://talentberry-public-data.s3.ap-south-1.amazonaws.com/Talentberry+Candidate+Format.xlsx";

const CreateCandidate = () => {
  const [formatVisible, setFormatVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [candidates, setCandidates] = useState([
    { first_name: "", last_name: "", email: "" },
  ]);
  const [excelFile, setExcelFile] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const handleAddCandidate = () => {
    setCandidates((prevCandidates) => [
      ...prevCandidates,
      { first_name: "", last_name: "", email: "" },
    ]);
  };

  const handleRemoveCandidate = (indexToRemove) => {
    setCandidates((prevCandidates) =>
      prevCandidates.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleSaveCandidates = async (values) => {
    try {
      await dispatch?.CandidateModel?.requestCreateCandidate(values);
      navigate(PATH.Candidates);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (data) => {
    setIsFileUploaded(!isFileUploaded);
    setExcelFile(data);
  };

  const handleSubmitFile = async () => {
    const formData = new FormData();
    formData.append("excel_file", excelFile);
    await dispatch.CandidateModel.requestCandidateBulkCreate(formData);
    alert("Bulk Candidate Creation Successful");
    setExcelFile(null);
    setIsFileUploaded(!isFileUploaded);
    window.location.assign(PATH.Candidates);
  };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          { title: "Candidates", href: PATH.Candidates },
          {
            title: <Text strong>Add Candidates</Text>,
            href: PATH.AddCandidate,
          },
        ]}
      ></Breadcrumb>
      <Card>
        <FlexBox style={{ flexDirection: "column", gap: "10px" }}>
          <Title level={4}>Create Multiple Candidate</Title>
          <MultipleCandidatesForm
            candidates={candidates}
            onAddCandidate={handleAddCandidate}
            onRemoveCandidate={handleRemoveCandidate}
            onSaveCandidates={handleSaveCandidates}
          />
        </FlexBox>
      </Card>
      <Divider>OR</Divider>
      <Card>
        <FlexBox style={{ flexDirection: "column", gap: "20px" }}>
          <Title level={3}>Bulk Creation with Excel</Title>
          <Text style={{ marginTop: 10 }}>
            Upload an Excel file (.XLSX) containing candidate information for
            bulk creation. The file should have one sheet named
            &quot;Candidates&quot; in the format specified below.{" "}
            <a href={candidateBulkCreationLink}>Click here</a> to download the
            format.
          </Text>
          <div
            onClick={() => setFormatVisible(!formatVisible)}
            style={{ color: "teal", marginTop: 10, marginBottom: 10 }}
          >
            {formatVisible ? "Hide Format" : "View Format in Browser"}
          </div>
          {formatVisible === true ? (
            <Table
              columns={[
                { title: "", dataIndex: "key" },
                { title: "A", dataIndex: "first_name" },
                { title: "B", dataIndex: "last_name" },
                { title: "C", dataIndex: "email" },
              ]}
              dataSource={[
                {
                  key: 1,
                  first_name: "John",
                  last_name: "Smith",
                  email: "john@gmail.com",
                },
                {
                  key: 2,
                  first_name: "James",
                  last_name: "Smith",
                  email: "james@gmail.com",
                },
              ]}
            ></Table>
          ) : null}
          <FileUpload onFileUpload={handleFileUpload} />
          {excelFile ? (
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <Typography.Text>{excelFile?.name}</Typography.Text>
              <a
                href="#"
                style={{ color: "blue", marginLeft: 14, fontSize: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExcelFile(null);
                  setIsFileUploaded(false);
                }}
              >
                [Remove]
              </a>
            </div>
          ) : null}
          <Divider />
          <div style={{ marginTop: "10px" }}>
            <Button
              disabled={!isFileUploaded}
              type="primary"
              onClick={handleSubmitFile}
            >
              Create Bulk Candidates
            </Button>
          </div>
        </FlexBox>
      </Card>
    </>
  );
};

export default CreateCandidate;
