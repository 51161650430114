const { Spin } = require("antd");

const CenteredSpin = ({ style = {} }) => (
  <div
    style={{
      width: "100%",
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
    }}
  >
    <Spin />
  </div>
);

export default CenteredSpin;
