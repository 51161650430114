/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { Button, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FlexBoxVertical } from "styles/styles";
import { isSuperAdmin } from "utils";

const Users = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isSuperAdmin()) {
      navigate("/dashboard");
    }
  }, [isSuperAdmin]);
  return <FlexBoxVertical>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
      <Typography.Text>Users</Typography.Text>
      <Button>Add Users</Button>
    </div>
  </FlexBoxVertical>;
};

export default Users;
