/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import {
  CREATE_COMPANY_URL,
  LIST_COMPANY_URL,
  COMPANY_DETAILS_URL,
  UPDATE_COMPANY_URL,
  ACTIVATE_COMPANY_STATUS_URL,
} from "constants/api";

const model = {
  state: {
    companies: [],
    loading: false,
    error: "",
    companyDetails: {},
  },
  reducers: {
    setCompanies: (state, payload) => {
      return {
        ...state,
        companies: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setCompanyDetails: (state, payload) => {
      return {
        ...state,
        companyDetails: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestGetCompanyList() {
      try {
        if (this.loading) true;
        const response = await api.get(LIST_COMPANY_URL);
        if (response) {
          this.setLoadingState(false);
          this.setCompanies(response);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async requestCreateCompany(payload) {
      try {
        if (this.loading) true;
        this.setLoadingState(true);
        const response = await api.post(CREATE_COMPANY_URL, payload);
        if (response) {
          return response.json();
        }
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      }
    },
    async requestGetCompanyDetails(companyId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${COMPANY_DETAILS_URL}/${companyId}`);
        if (response) {
          this.setCompanyDetails(response);
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async requestUpdateCompanyDetails(companyId, state, payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(
          `${UPDATE_COMPANY_URL}/${companyId}`,
          payload
        );
        if (response) {
          console.log("rucd response", response);
          this.setCompanyDetails(response);
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async verifyCompanyStatus(companyId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(
          `${ACTIVATE_COMPANY_STATUS_URL}/${companyId}`
        );
        if (response) {
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
  }),
};
export default model;
