import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
const ModifyQuestionButton = ({ record = null }) => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => {
        if (record) {
          dispatch?.QuestionModel?.toggleModificationModalState({
            isOpen: true,
            questionObj: record,
          });
        }
      }}
    >
      <EditOutlined />
      Modify
    </Button>
  );
};

export default ModifyQuestionButton;
