/* eslint-disable import/no-unresolved */
import { Card, Typography, Table, Button, Spin, Breadcrumb } from "antd";
import { api } from "axiosRequest";
import FileUpload from "common/FileUpload";
import { PATH } from "constants/PATH";
import { INTERVIEW_BULK_CREATE_URL } from "constants/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const interviewCreationFormatLink =
  "https://talentberry-public-data.s3.ap-south-1.amazonaws.com/Talentberry+Bulk+Assessment+Create+Format.xlsx";

const BulkAddInterviews = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [buttonLock, setButtonLock] = useState(false);
  const uploadExcel = async () => {
    if (file) {
      const myForm = new FormData();
      myForm.append("excel_file", file);
      setButtonLock(true);
      try {
        const resp = await api.post(INTERVIEW_BULK_CREATE_URL, myForm);
        if (resp) {
          alert("Created successfully");
          navigate(PATH.Interviews);
        }
      } catch (e) {
        alert("ERROR: " + e.toString());
      } finally {
        setButtonLock(false);
      }
    }
  };
  return (
    <>
      <Breadcrumb
        items={[
          { title: "Assessments", href: PATH.Interviews },
          { title: "Add Assessment", href: PATH.Interviews },
          {
            title: <Typography.Text strong>Bulk Add Assessment</Typography.Text>,
            href: PATH.BulkAddInterview,
          },
        ]}
      ></Breadcrumb>
      <Card>
        <>
          <Typography.Title level={4}>Bulk Add Assessment</Typography.Title>
          <Typography.Text style={{ marginTop: 10, marginBottom: 20 }}>
            You can upload an Excel (.xlsx) file here to bulk create multiple
            assessments at once. The file should have one sheet named
            &quot;Assessment&quot; in the format specified below.{" "}
            <a href={interviewCreationFormatLink}>Click here</a> to download the
            sample format.
          </Typography.Text>
          <Table
            style={{ marginTop: 10 }}
            columns={[
              { title: "", dataIndex: "key" },
              { title: "A", dataIndex: "first_name" },
              { title: "B", dataIndex: "last_name" },
              { title: "C", dataIndex: "email" },
              { title: "D", dataIndex: "job_profile_id" },
            ]}
            dataSource={[
              {
                key: 1,
                first_name: "first_name",
                last_name: "last_name",
                email: "email",
                job_profile_id: "job_profile_id",
              },
              {
                key: 2,
                first_name: "John",
                last_name: "Smith",
                email: "john@gmail.com",
                job_profile_id: 5,
              },
              {
                key: 3,
                first_name: "James",
                last_name: "Smith",
                email: "james@gmail.com",
                job_profile_id: 6,
              },
            ]}
          />
          {file ? (
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <Typography.Text>{file?.name}</Typography.Text>
              <a
                href="#"
                style={{ color: "blue", marginLeft: 14, fontSize: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFile(null);
                }}
              >
                [Remove]
              </a>
            </div>
          ) : null}
          <FileUpload
            accept=".xlsx"
            onFileUpload={(e) => {
              console.log(e);
              setFile(e);
            }}
          />
        </>
        {buttonLock ? (
          <Spin size="default" />
        ) : (
          <Button onClick={uploadExcel} disabled={!file && buttonLock} style={{marginTop: 20}}>
            Upload
          </Button>
        )}
      </Card>
    </>
  );
};

export default BulkAddInterviews;
