/* eslint-disable import/no-unresolved */
// import {
//   Typography
// } from "antd";
import { Card, Progress, Tag, Typography } from "antd";
import { api } from "axiosRequest";
// import AreasCard from "components/AreasOfImprovementCard/AreasOfImprovementCard";
import CenteredSpin from "components/CenteredSpin";
import { ROLEPLAY_GET_INSTANCE_RESULT } from "constants/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ScoreCard = ({ percent }) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%",
        marginRight: 10,
        height: 250,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Score"
    >
      <Progress type="circle" percent={percent}></Progress>
    </Card>
  );
};

const AOIC = ({ areas = [] }) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%",
        marginRight: 10,
        height: 150,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Areas of Improvement"
    >
      {areas?.map((x) => (
        <Tag key={x} color="skyblue">
          {x}
        </Tag>
      ))}
    </Card>
  );
};

const DurationCard = ({ start = null, end = null }) => {
  const xmin =
    start && end
      ? ((new Date(end).getTime() - new Date(start).getTime()) / (3600 * 1000))
          .toString()
          .substring(0, 5)
      : "5.2";
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%",
        marginRight: 10,
        height: 150,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Time Taken for Roleplay"
    >
      Completed in{" "}
      <span style={{ fontWeight: "bold", color: "red" }}>{xmin}</span> minutes
    </Card>
  );
};

const SummaryCard = ({
  summary = "The candidate showed remarkable progress",
  candidate_full_name = "Test User",
}) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%",
        marginRight: 10,
        minHeight: 250,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Summary of Discussion"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%  ",
        }}
      >
        <Typography.Text style={{ fontWeight: "bold", marginBottom: 20 }}>
          {candidate_full_name}
        </Typography.Text>
      </div>
      <div>
        <Typography.Text>{summary}</Typography.Text>
      </div>
    </Card>
  );
};
const TranscriptCard = ({ messages = [] }) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%",
        marginRight: 10,
        minHeight: 250,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Transcript"
    >
      {messages
        .filter((x) => x.role !== "system")
        .map((x) => ({
          ...x,
          role:
            x.role === "user" ? (
              <span style={{ color: "skyblue", fontWeight: "bold" }}>
                Candidate
              </span>
            ) : (
              <span>AI Roleplay Bot</span>
            ),
        }))
        .map((x) => (
          <div key={x}>
            <Typography.Text>
              {x.role} : {x.content}
            </Typography.Text>
          </div>
        ))}
    </Card>
  );
};

const SkillCard = ({ skillRatings = [] }) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        minHeight: 250,
        margin: "0 auto",
        padding: 20,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      title="Skills"
    >
      {Object.keys(skillRatings).map((skill) => (
        <div
          key={skill}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: 16,
          }}
        >
          <Typography.Text strong style={{ marginBottom: 8 }}>
            {skill.charAt(0).toUpperCase() + skill.slice(1)}
          </Typography.Text>
          <Progress
            percent={(skillRatings[skill] / 5) * 100}
            showInfo={false}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            trailColor="#d9d9d9"
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </Card>
  );
};

const ViewRoleplay = () => {
  const { id } = useParams();
  const instanceUUID = id;
  const [instanceData, setInstanceData] = useState(null);
  // console.log(instanceData);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (instanceUUID) {
      setIsLoading(true);
      api.get(`${ROLEPLAY_GET_INSTANCE_RESULT}/${instanceUUID}`).then((res) => {
        setInstanceData(res);
      });
      setIsLoading(false);
    }
  }, [instanceUUID]);
  if (isLoading || instanceData === null) {
    return <CenteredSpin />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography.Title level={3}>Roleplay Result</Typography.Title>
      <Typography.Title level={5}>
        Interview ID : {instanceUUID}
      </Typography.Title>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <SummaryCard
          candidate_full_name={instanceData?.candidate_full_name}
          summary={instanceData?.summary}
        />
        <SkillCard skillRatings={instanceData?.skillRatings} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          marginTop: 10,
        }}
      >
        <DurationCard
          start={instanceData?.roleplay_started_at}
          end={instanceData?.roleplay_completed_at}
        />
        <AOIC areas={instanceData?.areasOfImprovement} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          marginTop: 10,
        }}
      >
        <ScoreCard percent={instanceData?.score} />
        <TranscriptCard messages={instanceData?.roleplay_messages || []} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          marginTop: 10,
        }}
      ></div>
      {/* {JSON.stringify(instanceData)} */}
    </div>
  );
};

export default ViewRoleplay;
