import { Button, Card, Input, Select, Steps, Typography } from "antd";
import { useEffect, useState } from "react";
import CenteredSpin from "../../components/CenteredSpin";
import { CheckCircleFilled } from "@ant-design/icons";
import { api } from "../../axiosRequest";
import {
  ROLEPLAY_CREATE_SCENARIO,
  ROLEPLAY_GENERATE_SCENARIOS,
  ROLEPLAY_GET_COMPANY_INFO,
} from "../../constants/api";

const modeObj = {
  CREATE_COMPANY: 1,
  CREATE_PRODUCT: 2,
  SELECT_JOB_PROFILE: 3,
  SELECT_SCENARIO: 4,
};

const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "80vw",
  minHeight: 400,
};
const roleplayStepDetails = [
  "Company Info",
  "Product Info",
  "Select Job Profile",
  "Select Scenario",
  "Finish",
].map((x) => ({
  title: x,
}));

const RoleplaySteps = ({ current }) => (
  <Steps
    current={current}
    items={roleplayStepDetails}
    style={{ marginBottom: 20 }}
  />
);

const CreationCard = ({
  handleNext = () => {},
  mode = modeObj.CREATE_PRODUCT,
  scenariosGenerated = ["SC1", "SC2"],
  jobProfileList = ["Job1", "Job2", "Job3"],
  rxgen = () => {},
  ival = {},
}) => {
  const [state, setState] = useState({});

  useEffect(() => {
    if (mode === modeObj.SELECT_JOB_PROFILE) {
      setState({ jpv: jobProfileList?.[0]?.id });
    } else if (mode === modeObj.CREATE_COMPANY) {
      setState({ name: ival.companyName, desc: ival.companyDescription });
    } else setState({});
  }, [mode]);
  if (mode === modeObj.CREATE_PRODUCT || mode === modeObj.CREATE_COMPANY)
    return (
      <>
        <RoleplaySteps current={mode - 1} />
        <Card
          title={
            mode === modeObj.CREATE_PRODUCT
              ? "Create Your Product/Service"
              : "Enter Company Info"
          }
          style={cardStyles}
        >
          <Typography.Text
            style={{ margin: 10, width: "100%", textAlign: "left" }}
          >
            {mode === modeObj.CREATE_COMPANY
              ? "Please enter the following details about your company"
              : "Please enter the details below about your product/service"}
          </Typography.Text>
          <Input
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            placeholder={
              mode === modeObj.CREATE_PRODUCT
                ? "Enter the name of your product/service"
                : "Enter the full name of your company"
            }
            style={{ margin: 10, width: "100%" }}
          ></Input>
          <Input
            placeholder={
              mode === modeObj.CREATE_PRODUCT
                ? "Describe what your product/service does"
                : "Enter the full description of your company's products and services"
            }
            value={state.desc}
            onChange={(e) => setState({ ...state, desc: e.target.value })}
            style={{ margin: 10, height: 300, width: "100%" }}
          ></Input>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{ margin: 0 }}
              onClick={() => handleNext(state)}
            >
              Next
            </Button>
          </div>
        </Card>
      </>
    );
  else if (mode === modeObj.SELECT_JOB_PROFILE) {
    return (
      <>
        <RoleplaySteps current={mode - 1} />
        <Card
          title={"Select Job Profile For Roleplay"}
          style={{
            ...cardStyles,
          }}
        >
          <div>
            <Typography.Text style={{ marginBottom: 20 }}>
              Select the job profile who needs to take the asssessment for this
              product or service
            </Typography.Text>
          </div>
          <div>
            <Select
              style={{
                minWidth: 400,
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 20,
              }}
              value={state.jpv}
              onChange={(e) => {
                console.log(e);
                setState({ jpv: e });
              }}
            >
              {jobProfileList.map((x) => (
                <Select.Option key={x.title} value={x.id}>
                  {x.title}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Button onClick={() => handleNext(state.jpv)}>Next</Button>
        </Card>
      </>
    );
  } else {
    return (
      <>
        <RoleplaySteps current={mode - 1} />
        <Card
          title={"Select Roleplay Scenario"}
          style={{
            ...cardStyles,
          }}
        >
          <Typography.Text>Select Your Scenario</Typography.Text>
          <div>
            {scenariosGenerated.map((x, n) => (
              <div
                style={{
                  width: "100%",
                  wordWrap: "break-word",
                  minHeight: 50,
                  border:
                    state?.selected === n
                      ? "1px solid green"
                      : "1px solid rgba(0,0,0,0.3)",
                  borderRadius: 10,
                  marginBottom: 10,
                  padding: 10,
                  cursor: "pointer",
                  backgroundColor:
                    state?.selected === n ? "rgba(0,100,0,0.3)" : "white",
                }}
                key={n}
                onClick={() => {
                  setState({ selected: n });
                }}
              >
                {x}
              </div>
            ))}
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  marginTop: 10,
                  backgroundColor: "blue",
                  color: "white",
                }}
                onClick={() => {
                  if (
                    Object.getOwnPropertyNames(state).includes("selected") &&
                    scenariosGenerated[state.selected]
                  )
                    handleNext(scenariosGenerated[state.selected]);
                }}
              >
                Continue
              </Button>
              <Button style={{ marginTop: 10, marginLeft: 10 }} onClick={rxgen}>
                Regenerate Scenarios
              </Button>
            </div>
          </div>
        </Card>
      </>
    );
  }
};

const CreateRoleplayPage = () => {
  const [mode, setMode] = useState(modeObj.CREATE_COMPANY);
  const [isLoading, setIsLoading] = useState(false);
  const [myJobs, setMyJobs] = useState([]);
  const [myScenarios, setMyScenarios] = useState([]);
  const [creationDetails, setCreationDetails] = useState({
    companyName: "",
    companyDescription: "",
    productName: "",
    productDescription: "",
    selectedJobProfileName: "",
    selectedJobProfileDescription: "",
    selectedScenario: "",
  });

  useEffect(() => {
    setIsLoading(true);
    api
      .get(ROLEPLAY_GET_COMPANY_INFO)
      .then(async (data) => {
        if (data) {
          setCreationDetails((prevDetails) => ({
            ...prevDetails,
            companyName: data.init.company_name,
            companyDescription: data.init.company_description,
          }));
          setMyJobs(data?.jobs);
          if (data.init.company_name && data.init.company_description) {
            setMode(modeObj.CREATE_PRODUCT);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const generateMyScenarios = async () => {
    try {
      setIsLoading(true);
      const res = await api.post(ROLEPLAY_GENERATE_SCENARIOS, {
        ...creationDetails,
      });
      console.log(res);
      setMyScenarios(res);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <CenteredSpin />;
  else {
    switch (mode) {
      case modeObj.CREATE_COMPANY:
        return (
          <CreationCard
            mode={mode}
            ival={creationDetails}
            handleNext={(x) => {
              setCreationDetails({
                ...creationDetails,
                companyName: x.name,
                companyDescription: x.desc,
              });
              setMode(modeObj.CREATE_PRODUCT);
            }}
          />
        );
      case modeObj.CREATE_PRODUCT:
        return (
          <CreationCard
            mode={mode}
            handleNext={(x) => {
              setCreationDetails({
                ...creationDetails,
                productName: x.name,
                productDescription: x.desc,
              });
              setMode(modeObj.SELECT_JOB_PROFILE);
            }}
          />
        );
      case modeObj.SELECT_SCENARIO:
        return (
          <CreationCard
            mode={mode}
            scenariosGenerated={myScenarios}
            handleNext={async (e) => {
              if (e) {
                setIsLoading(true);
                const updatedCreationDetails = {
                  ...creationDetails,
                  selectedScenario: e,
                };
                setCreationDetails(updatedCreationDetails);
                api
                  .post(ROLEPLAY_CREATE_SCENARIO, updatedCreationDetails)
                  .then((res) => {
                    if (res) {
                      setCreationDetails({
                        ...updatedCreationDetails,
                        ...res,
                      });
                    }
                    setIsLoading(false);
                    setMode(modeObj.FINISH);
                  })
                  .catch((error) => {
                    alert(error.toString());
                    setIsLoading(false);
                  });
              }
            }}
            rxgen={generateMyScenarios}
          />
        );
      case modeObj.SELECT_JOB_PROFILE:
        return (
          <CreationCard
            mode={mode}
            jobProfileList={myJobs}
            handleNext={async (y) => {
              console.log(y);
              const xyz = myJobs.filter((x) => x.id === y).pop();
              if (xyz && xyz.id && xyz.job_description) {
                setCreationDetails({
                  ...creationDetails,
                  selectedJobProfileName: xyz.title,
                  selectedJobProfileDescription: xyz.job_description,
                  selectedJobProfileId: xyz.id,
                });
                setIsLoading(true);
                await generateMyScenarios();
                setMode(modeObj.SELECT_SCENARIO);
              }
            }}
          />
        );
      case modeObj.FINISH:
        return (
          <Card
            title="Roleplay Created"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "80vw",
              height: 400,
              borderRadius: "10px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "margin-bottom": 20,
              }}
            >
              <CheckCircleFilled style={{ color: "#52c41a", fontSize: 100 }} />
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#333",
                marginBottom: "10px",
              }}
            >
              Your roleplay has been created. You can now assign it to a
              candidate from the{" "}
              <span style={{ fontWeight: "bold" }}>
                &quot;Assign Roleplay&quot;
              </span>{" "}
              tab.
            </div>
            <div style={{ fontSize: "14px", color: "#666", marginTop: 10 }}>
              <strong>Scenario:</strong> {creationDetails.selectedScenario}
            </div>
            <div style={{ fontSize: "14px", color: "#666", marginTop: 10 }}>
              <strong>Your candidate will be speaking to:</strong>{" "}
              {creationDetails.scenarioResponderName}
            </div>
            <div style={{ fontSize: "14px", color: "#666", marginTop: 10 }}>
              <strong>Customer Background:</strong>{" "}
              {creationDetails.scenarioResponderBackground}
            </div>
          </Card>
        );
      default:
        return <div>AN ERROR OCCURRED. PLEASE TRY AGAIN</div>;
    }
  }
};

export default CreateRoleplayPage;
