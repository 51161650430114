/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import {
  CREATE_JOB_URL,
  LIST_JOB_URL,
  JOB_DETAILS_URL,
  JOB_UPDATE_URL,
  ACTIVATE_JOB_STATUS_URL,
  JOB_DELETE_URL,
  JOB_EMAIL_TEMPLATE_UPDATE_URL,
} from "constants/api";

const model = {
  state: {
    jobs: [],
    loading: false,
    error: "",
    jobDetails: {},
  },
  reducers: {
    setJobs: (state, payload) => {
      return {
        ...state,
        jobs: payload,
      };
    },
    setJobDetails: (state, payload) => {
      return {
        ...state,
        jobDetails: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestGetJobList() {
      try {
        this.setLoadingState(true);
        const response = await api.get(LIST_JOB_URL);
        if (response) {
          this.setLoadingState(false);
          this.setJobs(response);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async requestDeleteJob(payload, rootState) {
      try {
        this.setLoadingState(true);
        const response = await api.post(JOB_DELETE_URL, { job_id: payload });
        if (response) return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      } finally {
        this.setLoadingState(false);
      }
    },
    async requestCreateJob(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CREATE_JOB_URL, payload);
        if (response) return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      }
    },
    async requestGetJobDetails(jobId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${JOB_DETAILS_URL}/${jobId}`);
        if (response) {
          this.setJobDetails(response);
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async requestUpdateEmailTemplate(jobId, rootState, payload) {
      try {
        this.setLoadingState(true);
        await api.post(JOB_EMAIL_TEMPLATE_UPDATE_URL, {
          email_template: payload,
          job_id: jobId,
        });
        const newState = {
          ...rootState?.JobModel?.jobDetails,
          email_template: payload,
        };
        this.setJobDetails(newState);
      } catch (e) {
        console.log(e);
      } finally {
        this.setLoadingState(false);
      }
    },
    async requestUpdateJobDetails(jobId, state, payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(`${JOB_UPDATE_URL}/${jobId}`, payload);
        if (response) {
          this.setJobDetails(response);
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async verifyJobStatus(jobId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${ACTIVATE_JOB_STATUS_URL}/${jobId}`);
        if (response) {
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
  }),
};
export default model;
