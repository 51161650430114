/* eslint-disable import/no-unresolved */

import CustomCard from "common/Card";
import React, { useState } from "react";
import InterviewSVG from "../../assets/Images/interviews.svg";
import JobSVG from "../../assets/Images/jobs.svg";

import axios from "axios";
import { api } from "axiosRequest";
import CenteredSpin from "components/CenteredSpin";
import { PATH } from "constants/PATH";
import { DASHBOARD_STATS } from "constants/api";
import styled from "styled-components";
import { isSuperAdmin } from "utils";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
`;
const Dashboard = () => {
  const [state, setState] = React.useState({
    completedInterviews: 0,
    pendingInterviews: 0,
    candidates: 0,
    jobProfilesCreated: 0,
  });

  const [statsLoading, setStatsLoading] = useState(false);

  React.useEffect(() => {
    if (statsLoading) return;
    let isMounted = true;
    const source = axios.CancelToken.source();
    setStatsLoading(true);
    api
      .get(DASHBOARD_STATS, { cancelToken: source.token })
      .then(async (r) => {
        if (isMounted) {
          setState(r);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err);
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        setStatsLoading(false);
      });

    return () => {
      isMounted = false;
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <div>
      {statsLoading ? (
        <CenteredSpin />
      ) : (
        <GridContainer>
          <>
            {isSuperAdmin() ? null : (
              <>
                <CustomCard
                  src={JobSVG}
                  cardTitle={state?.jobProfilesCreated?.toString()}
                  cardDescription={"Job Profiles Created"}
                  path={PATH.Jobroles}
                  width="100%"
                />
                <CustomCard
                  src={InterviewSVG}
                  cardTitle={state?.completedInterviews?.toString()}
                  cardDescription={"Completed Assessments"}
                  path={PATH.Interviews}
                  width="100%"
                />
                <CustomCard
                  src={JobSVG}
                  cardTitle={state?.pendingInterviews?.toString()}
                  cardDescription={"Pending Assessments"}
                  path={PATH.Interviews}
                  width="100%"
                />
                <CustomCard
                  src={InterviewSVG}
                  cardTitle={state?.candidates.toString()}
                  cardDescription={"Candidates"}
                  path={PATH.Candidates}
                  width="100%"
                />
              </>
            )}
          </>
          {isSuperAdmin() ? (
            <>
              <CustomCard
                superAdminCard={true}
                src={InterviewSVG}
                cardTitle={state?.companies?.toString()}
                cardDescription={"Companies"}
                path={PATH.Companies}
                width="100%"
              />
              <CustomCard
                superAdminCard={true}
                src={JobSVG}
                cardTitle={state?.users?.toString()}
                cardDescription={"Users"}
                path={PATH.Candidates}
                width="100%"
              />
            </>
          ) : null}
        </GridContainer>
      )}
    </div>
  );
};

export default Dashboard;
