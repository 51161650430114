/* eslint-disable import/no-unresolved */
import React from "react";
import { Typography } from "antd";
import ContactSalesComponent from "components/ContactSales";

const ContactSalesPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "100vh",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <div style={{ minWidth: 500, borderRadius: 10, marginLeft: 20 }}>
        <img
          src="/contact-sales.jpg"
          alt="abc"
          width={750}
          height={550}
          style={{ borderRadius: 10 }}
        ></img>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title level="h4">
          Welcome To <span style={{ color: "royalblue" }}>TalentBerry</span>
        </Typography.Title>
        <Typography.Text style={{ margin: 10, width: 500, textAlign: "left" }}>
          Thank you for your interest in TalentBerry. Please enter the following
          information and our sales team will get in touch with you soon.
        </Typography.Text>
        <ContactSalesComponent />
      </div>
    </div>
  );
};

export default ContactSalesPage;
