/* eslint-disable import/no-unresolved */

import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Select, Typography, notification } from "antd";
import { api } from "axiosRequest";
import Modal from "common/Modal";
import TextArea from "common/TextArea";
import { QUESTION_DELETE_URL, QUESTION_UPDATE_URL } from "constants/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListQuestion from "./questions";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const NumberOfQuestions = ({ jobId, jobDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const qmdata = useSelector((x) => x?.QuestionModel);
  const { questions, modificationModalState } = qmdata;
  const closeQuestionModificationModal = () => {
    dispatch?.QuestionModel?.toggleModificationModalState(null);
  };

  const [currentQuestion, setCurrentQuestion] = useState("");
  const [questionSequence, setQuestionSequence] = useState(0);
  const [qlock, setqlock] = useState(false);
  const updateQuestion = async () => {
    if (qlock) return;
    try {
      const resp = await api.post(QUESTION_UPDATE_URL, modificationModalState?.questionObj);
      if(resp) {
        alert("Question updated!");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setqlock(false);
    }
  };
  const handleAddQuestion = () => {
    setOpenModal(!openModal);
  };
  const handleCancelModal = () => {
    setOpenModal(!openModal);
    setCurrentQuestion("");
    setQuestionSequence((prevSequence) => prevSequence - 1);
  };

  const handleTextAreaChange = (e) => {
    setCurrentQuestion(e.target.value);
  };

  useEffect(() => {
    if (jobDetails) {
      setQuestionSequence(jobDetails?.last_qstn_sequence + 1);
    }
  }, [jobDetails]);

  const handleSubmitQuestion = () => {
    try {
      const payload = {
        question: currentQuestion,
        sequence: questionSequence,
        job: jobId,
      };
      dispatch.QuestionModel.requestCreateQuestion(jobId, payload);

      setQuestionSequence((prevSequence) => prevSequence + 1);
      setOpenModal(!openModal);
    } catch (error) {
      console.error("Error adding question:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while adding the question.",
      });
    }

    setCurrentQuestion("");
  };

  const handleDelete = async () => {
    let confirmDelete = confirm("Are you sure you want to delete this?");
    if(!confirmDelete) return;
    const {questionObj} = modificationModalState;
    await api.post(QUESTION_DELETE_URL, questionObj);
    alert("Deleted Successfully");
    navigate(0);
  }
  return (
    <Card>
      <Flex justify="space-between" align="center">
        <Title level={5}>Questions</Title>
        <Button
          type="primary"
          size="large"
          onClick={handleAddQuestion}
          icon={<PlusOutlined style={{ fontSize: "20px" }} />}
        >
          Add Question
        </Button>
      </Flex>
      <ListQuestion jobId={jobId} />
      <Modal
        title="Modify Question"
        centered={true}
        okText="Update"
        cancelText={"Cancel"}
        open={modificationModalState?.isOpen}
        closeIcon={true}
        onCancel={closeQuestionModificationModal}
        handleSubmit={async () => {
          await updateQuestion();
          closeQuestionModificationModal();
          // navigate(0);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography.Text style={{ fontWeight: "bold", marginRight: 10 }}>
            Sequence:{" "}
          </Typography.Text>
          <Select
            style={{ width: "80%" }}
            value={modificationModalState?.questionObj?.sequence}
            onChange={(e) => {
              dispatch.QuestionModel.toggleModificationModalState({
                ...modificationModalState,
                questionObj: {
                  ...modificationModalState?.questionObj,
                  sequence: e,
                },
              });
            }}
          >
            {Array(questions?.length || 0)
              .fill(0)
              .map((_, n) => (
                <Select.Option key={n + 1} value={n + 1}>
                  {n + 1}
                </Select.Option>
              ))}
          </Select>
        </div>
        <Typography.Text style={{ fontWeight: "bold", marginTop: 10 }}>
          Question:{" "}
        </Typography.Text>
        <TextArea
          value={modificationModalState?.questionObj?.question}
          rows={4}
          onChange={(e) => {
            const newState = {
              ...modificationModalState,
              questionObj: {
                ...modificationModalState?.questionObj,
                question: e?.target?.value || "",
              },
            };
            dispatch.QuestionModel.toggleModificationModalState(newState);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            type="primary"
            color="danger"
            danger={{ color: "red" }}
            onClick={handleDelete}
          >
            Delete Question
          </Button>
        </div>
      </Modal>
      {openModal && (
        <Modal
          title="Add Question"
          centered={true}
          okText="Add"
          cancelText={"Cancel"}
          open={openModal}
          closeIcon={true}
          onCancel={handleCancelModal}
          handleSubmit={handleSubmitQuestion}
        >
          <div>
            <TextArea
              rows={4}
              value={currentQuestion}
              onChange={handleTextAreaChange}
            />
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default NumberOfQuestions;
