/* eslint-disable import/no-unresolved */

import { EyeFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { Button, Col, Form, Input, Row } from "antd";
import CenteredSpin from "components/CenteredSpin";
import debugLog from "env/debugLog";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FlexBox } from "styles/styles";

const AddCompanyForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [uploadPreview, setUploadPreview] = useState(null);
  const imgRef = useRef();
  const values = Form.useWatch([], form);
  const [pwVisible, setPwVisible] = useState(false);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          debugLog("false");
          console.log(values);
          setSubmittable(false);
        }
      );
  }, [values]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      if (loading) return;
      console.log(logoFile, setLoading);
      const keys = Object.keys(values);
      const formData = new FormData();
      for (let key of keys) {
        formData.append(key, values[key]);
      }
      const logo_base64 = uploadPreview
        ? uploadPreview.split("base64,")?.[1]
        : null;
      formData.append("logo_base64", logo_base64);
      await dispatch?.CompanyModel?.requestCreateCompany(formData);
      alert("Company created!");
    } catch (error) {
      alert("ERROR: " + error.toString());
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <CenteredSpin />;
  return (
    <Form
      form={form}
      name="addCompany"
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Typography.Text
        style={{
          marginTop: 20,
          marginBottom: 20,
          textDecoration: "underline",
          fontSize: 20,
          textAlign: "center",
        }}
        level={3}
      >
        Company Details
      </Typography.Text>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="company_name"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter the company name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="industry"
            label="Industry"
            rules={[
              {
                required: true,
                message: "Please enter the industry",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="company_website"
            label="Website Link"
            rules={[
              {
                required: true,
                message: "Please enter the website link",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter address line 1",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="pincode"
            label="Pincode"
            rules={[
              {
                required: true,
                message: "Please enter the pincode",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "Please enter the state",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "Please enter the city",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Text
        style={{
          marginTop: 10,
          marginBottom: 10,
          textDecoration: "underline",
          fontSize: 20,
          textAlign: "center",
        }}
        level={3}
      >
        Liason Details
      </Typography.Text>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please enter first name of liason",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please enter last name of liason",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[
              {
                required: true,
                message: "Please enter last name of liason",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="email"
            label="Admin Email"
            rules={[
              {
                required: true,
                message: "Please enter email of liason",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="password"
            label="Admin Password"
            rules={[
              {
                required: true,
                message: "Please enter the password",
              },
            ]}
          >
            <Input type={pwVisible ? "text" : "password"} />
          </Form.Item>
          <span
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <EyeFilled
              size={40}
              color={pwVisible ? "blue" : "black"}
              onClick={() => setPwVisible(!pwVisible)}
              style={{ color: pwVisible ? "blue" : "black", fontSize: 30 }}
            />
          </span>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="logo"
            label="Company Logo"
            rules={[
              {
                required: false,
                message: "Please upload the company logo",
              },
            ]}
          >
            {uploadPreview ? (
              <img
                width={100}
                height={100}
                style={{
                  borderRadius: 100,
                  border: "1px solid rgba(0,0,200,0.5)",
                }}
                src={uploadPreview}
                onClick={() => {
                  setUploadPreview(null);
                  setLogoFile(null);
                }}
                alt="Logo"
              ></img>
            ) : (
              <div style={{ width: 100, height: 100 }}>
                <Button
                  onClick={() => {
                    imgRef.current.click();
                  }}
                >
                  Upload Logo
                </Button>
              </div>
            )}
            <input
              style={{ display: "none" }}
              name="logo"
              ref={imgRef}
              type="file"
              accept="image/jpeg,image/png"
              onChange={(e) => {
                debugLog(e);
                if (e.target.files && e.target.files[0]) {
                  const tgt = e.target.files[0];
                  setLogoFile(tgt);
                  const reader = new FileReader();
                  reader.readAsDataURL(tgt);
                  reader.onload = () => {
                    setUploadPreview(reader.result);
                  };
                }
              }}
            ></input>
          </Form.Item>
        </Col>
      </Row>
      <FlexBox style={{ justifyContent: "flex-end" }}>
        <Button type="primary" htmlType="submit" disabled={!submittable}>
          Create Company
        </Button>
      </FlexBox>
    </Form>
  );
};

export default AddCompanyForm;

//
