import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Space, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/PATH";
import { useNavigate } from "react-router-dom";
import CenteredSpin from "../../components/CenteredSpin";

const SubmitButton = ({ form }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        title: values.title,
        job_description: values.description,
        ai_generated_questions: values.ai_generated_questions,
      };
      await dispatch?.JobModel?.requestCreateJob(payload);
      setIsLoading(false);
      navigate(PATH.Jobroles);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <CenteredSpin />;
  } else
    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        onClick={handleSubmit}
      >
        Add Job
      </Button>
    );
};

const AddJobForm = () => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      size="large"
    >
      <Form.Item
        name="title"
        label="Job Title"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Job Description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={6} />
      </Form.Item>
      <Flex justify="space-between">
        <Form.Item name="ai_generated_questions" label="AI generated questions">
          <Switch checkedChildren="ON" unCheckedChildren="OFF" />
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton form={form} />
          </Space>
        </Form.Item>
      </Flex>
    </Form>
  );
};
export default AddJobForm;
