// /* eslint-disable import/no-unresolved */
// import { Card, Typography, Table, Button, Spin, Breadcrumb } from "antd";
// import { api } from "axiosRequest";
// import FileUpload from "common/FileUpload";
// import { PATH } from "constants/PATH";
// import { INTERVIEW_BULK_CREATE_URL } from "constants/api";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";

// const CreateReport = () => {
//   const navigate = useNavigate();
//   const [file, setFile] = useState(null);
//   const [buttonLock, setButtonLock] = useState(false);
//   const uploadExcel = async () => {
//     if (file) {
//       const myForm = new FormData();
//       myForm.append("excel_file", file);
//       setButtonLock(true);
//       try {
//         const resp = await api.post(INTERVIEW_BULK_CREATE_URL, myForm);
//         if (resp) {
//           alert("Created successfully");
//           navigate(PATH.Interviews);
//         }
//       } catch (e) {
//         alert("ERROR: " + e.toString());
//       } finally {
//         setButtonLock(false);
//       }
//     }
//   };
//   return (
//     <>
//       <Breadcrumb
//         items={[
//           { title: "Dashboard", href: PATH.Dashboard },
//           {
//             title: <Typography.Text strong>Upload Reports</Typography.Text>,
//             href: PATH.UploadReport,
//           },
//         ]}
//       ></Breadcrumb>
//       <Card>
//         <>
//           <Typography.Title level={4}>Upload Report</Typography.Title>
//           <Typography.Text style={{ marginTop: 10, marginBottom: 20 }}>
//             Select the appropriate
//           </Typography.Text>
//           <Table
//             style={{ marginTop: 10 }}
//             columns={[
//               { title: "", dataIndex: "key" },
//               { title: "A", dataIndex: "first_name" },
//               { title: "B", dataIndex: "last_name" },
//               { title: "C", dataIndex: "email" },
//               { title: "D", dataIndex: "job_profile_id" },
//             ]}
//             dataSource={[
//               {
//                 key: 1,
//                 first_name: "first_name",
//                 last_name: "last_name",
//                 email: "email",
//                 job_profile_id: "job_profile_id",
//               },
//               {
//                 key: 2,
//                 first_name: "John",
//                 last_name: "Smith",
//                 email: "john@gmail.com",
//                 job_profile_id: 5,
//               },
//               {
//                 key: 3,
//                 first_name: "James",
//                 last_name: "Smith",
//                 email: "james@gmail.com",
//                 job_profile_id: 6,
//               },
//             ]}
//           />
//           {file ? (
//             <div
//               style={{
//                 justifyContent: "flex-start",
//                 alignItems: "center",
//                 display: "flex",
//                 flexDirection: "row",
//                 marginBottom: 20,
//               }}
//             >
//               <Typography.Text>{file?.name}</Typography.Text>
//               <a
//                 href="#"
//                 style={{ color: "blue", marginLeft: 14, fontSize: 10 }}
//                 onClick={(e) => {
//                   e.preventDefault();
//                   e.stopPropagation();
//                   setFile(null);
//                 }}
//               >
//                 [Remove]
//               </a>
//             </div>
//           ) : null}
//           <FileUpload
//             accept=".xlsx"
//             onFileUpload={(e) => {
//               console.log(e);
//               setFile(e);
//             }}
//           />
//         </>
//         {buttonLock ? (
//           <Spin size="default" />
//         ) : (
//           <Button onClick={uploadExcel} disabled={!file && buttonLock}>
//             Upload
//           </Button>
//         )}
//       </Card>
//     </>
//   );
// };

// export default CreateReport;
const abc = () => <div>Create Report</div>;

export default abc;
