/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from "react";
import { Descriptions, Col, Row, Typography, Card } from "antd";
import { api } from "axiosRequest";
import { COMPANY_GET_PROFILE_INFO } from "constants/api";
import CenteredSpin from "components/CenteredSpin";
// import { getItemFromLocalStorage, getCapitalizeFirstLetter } from "utils";
// import { getCapitalizeFirstLetter } from "utils";
const { Title } = Typography;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({});

  const formFields = [
    { key: "id", label: "Company ID" },
    { key: "company_name", label: "Company Name" },
    { key: "company_address", label: "Company Address" },
    { key: "first_name", label: "Admin First Name" },
    { key: "last_name", label: "Admin Last Name" },
    { key: "email", label: "Admin Email" },
    { key: "logo_base64", label: "Logo" },
    { key: "industry", label: "Industry" },
  ];

  useEffect(() => {
    if (loading) return;
    setLoading(true);
    api
      .get(COMPANY_GET_PROFILE_INFO)
      .then(
        (r) => {
          setProfileData(r);
        },
        (rej) => {
          alert("Could not fetch data, promise rejected " + rej.toString());
        }
      )
      .catch((err) =>
        alert("Could not fetch data, promise rejected " + err.toString())
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fieldResolve = (fkey, profileData) => {
    if (fkey === "active_status")
      return profileData[fkey] ? "Active" : "Inactive";
    else if (fkey === "logo_base64")
      return profileData[fkey] ? (
        <img
          alt="logo"
          src={profileData[fkey]}
        ></img>
      ) : null;
    else return profileData[fkey];
  };
  if (loading) return <CenteredSpin />;
  else
    return (
      <Card>
        <Row
          gutter={16}
          justify="space-between"
          align="middle"
          style={{ marginBottom: "20px" }}
        >
          <Col span={16}>
            <Title level={5}>Profile Details</Title>
          </Col>
        </Row>
        <Descriptions layout="vertical" bordered>
          {formFields.map((field) => (
            <Descriptions.Item key={field.key} label={field.label}>
              {fieldResolve(field.key, profileData)}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>
    );
};

export default Profile;
