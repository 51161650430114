/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import { PATH } from "constants/PATH";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import CenteredSpin from "components/CenteredSpin";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lm = useSelector((x) => x.LoginModel);
  const { loading } = lm;
  const onFinish = async (values) => {
    const { email, password } = values;
    try {
      const payload = { email: email, password: password };
      // Dispatching the `requestPostLogin` action from the LoginModel
      console.log("j1");
      await dispatch?.LoginModel?.requestPostLogin(payload);
      console.log("j2");
      const updatedUserData = getItemFromLocalStorage("data");
      if (updatedUserData?.token) {
        navigate(PATH.Dashboard);
      }
    } catch (error) {
      alert("ERROR: " + error.toString());
    }
  };
  return (
    <div>
      <Form
        name="normal_login"
        style={{ width: "40vw" }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember Me</Checkbox>
          </Form.Item>

          <a
            className="login-form-forgot"
            href="/forget-password"
            style={{ marginLeft: 10 }}
          >
            Forgot password
          </a>
        </Form.Item>
        <Form.Item>
          {loading ? (
            <CenteredSpin style={{ width: 200, height: "auto" }} />
          ) : (
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Login
            </Button>
          )}
        </Form.Item>
        <Typography.Text>
          Interested in using our platform?{" "}
          <a
            className="login-form-forgot"
            href="/contact-sales"
            style={{ marginLeft: 10 }}
          >
            Join Talentberry Today!
          </a>
        </Typography.Text>
      </Form>
    </div>
  );
};
export default Login;
