/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Space, Button, Table } from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
// import AvatarUpload from "common/AvatarUpload";
import Input from "common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;
const tableColumns = [
  { dataIndex: "first_name", title: "Admin First Name" },
  { dataIndex: "last_name", title: "Admin Last Name" },
  { dataIndex: "email", title: "Admin Email", width: 400 },
];
const CompanyDetails = () => {
  const dispatch = useDispatch();
  const { companyDetails } = useSelector((state) => state?.CompanyModel);
  const { id } = useParams();

  const formFields = [
    { key: "company_name", label: "Company Name" },
    { key: "industry", label: "Industry" },
    { key: "company_website", label: "Website Link" },
    { key: "company_address", label: "Address" },
  ];

  const [formData, setFormData] = useState(companyDetails);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setFormData(companyDetails);
  }, [companyDetails]);

  useEffect(() => {
    dispatch.CompanyModel.requestGetCompanyDetails(id);
  }, [id]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    await dispatch?.CompanyModel?.requestUpdateCompanyDetails(id, formData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFormData(companyDetails);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div>
      <Row
        gutter={16}
        justify="space-between"
        align="middle"
        style={{ marginBottom: "10px" }}
      >
        <Col span={16}>
          <Title level={4}>Company Details</Title>
        </Col>
        <Col span={8}>
          {isEditing ? (
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button icon={<SaveOutlined />} onClick={handleSave}>
                Save
              </Button>
              <Button
                icon={<CloseOutlined />}
                onClick={handleCancel}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </Button>
            </Space>
          ) : (
            <Button
              icon={<EditOutlined />}
              onClick={handleEdit}
              style={{ marginLeft: 10 }}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: "16px 0" }}>
        {formFields.map((field) => {
          return (
            <Col span={8} key={field.key} style={{ marginBottom: "16px" }}>
              <Text strong>{field.label}: </Text>
              {isEditing ? (
                <Input
                  value={formData[field.key]}
                  onChange={(e) => handleChange(field.key, e.target.value)}
                />
              ) : (
                <Text>{formData[field.key]}</Text>
              )}
            </Col>
          );
        })}
      </Row>
      <Table
        onHeaderRow={() => ({ style: { fontWeight: "bold" } })}
        dataSource={[formData]}
        columns={tableColumns}
      ></Table>
    </div>
  );
};

export default CompanyDetails;
