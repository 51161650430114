import { init } from "@rematch/core";
// Importing various models used in the application
import LoginModel from "./models/Login"
import InterviewModel from "./models/InterviewList";
import JobModel from "./models/Jobs";
import CompanyModel from "./models/Companies";
import CandidateModel from "./models/Candidates";
import QuestionModel from "./models/Questions"

// Initializing the Redux store with models
const store = init({
  models: {
    LoginModel,
    InterviewModel,
    JobModel,
    CompanyModel,
    CandidateModel,
    QuestionModel
  }
});

export default store;