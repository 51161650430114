/* eslint-disable import/no-unresolved */
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
  notification,
} from "antd";
import Input from "common/Input";
import TextArea from "common/TextArea";
import CenteredSpin from "components/CenteredSpin";
import { PATH } from "constants/PATH";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;
const defaultTemplate = `Hi {first_name} {last_name},

As part of {company_name} 's upskilling process, here is your assessment for the profile of {profile_name}. Below are the details for the assessment:

Link: {assessment_link}
Passcode: {passcode}

Warm Regards,
Team {company_name}
`;

const JobProfileDetails = ({ jobId, jobDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    notificationApi.open({
      message,
      placement: "topRight",
    });
  };
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const JobModelState = useSelector((x) => x.JobModel);
  const changeEmailTemplate = async (x) => {
    let template = x || defaultTemplate;
    await dispatch?.JobModel?.requestUpdateEmailTemplate(jobId, template);
  };
  const { loading } = JobModelState;
  useEffect(() => {
    setFormData(jobDetails);
  }, [jobDetails]);

  const switchStyle = {
    background: formData?.is_active ? "#52c41a" : "#f5222d", // Set background color based on status
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    const payload = {
      title: formData.title,
      job_description: formData.job_description,
      is_active: formData.is_active,
    };
    dispatch?.JobModel?.requestUpdateJobDetails(jobId, payload);
    setIsEditing(false);
  };

  const handleCancel = () => {
    // Reset the form data to the original data when canceling
    setFormData(jobDetails);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [emailModalOpen, setEmailModalOpen] = useState(null);
  return (
    <>
      {contextHolder}
      {loading ? (
        <CenteredSpin />
      ) : (
        <>
          <Card>
            {/* First Row */}
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={16}>
                <Title level={5}>Job Profile Details</Title>
              </Col>
              <Col span={8} style={{ textAlign: "right" }}>
                <Space>
                  {isEditing ? (
                    <>
                      <Button icon={<SaveOutlined />} onClick={handleSave}>
                        Save
                      </Button>
                      <Button icon={<CloseOutlined />} onClick={handleCancel}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        icon={<MailOutlined />}
                        onClick={() => setEmailModalOpen(true)}
                      >
                        Set Email Template
                      </Button>
                      <Button icon={<EditOutlined />} onClick={handleEdit}>
                        Edit
                      </Button>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={async () => {
                          let deleteConfirmed = confirm(
                            "Are you sure you want to delete this profile?"
                          );
                          if (deleteConfirmed) {
                            await dispatch?.JobModel?.requestDeleteJob(jobId);
                            navigate(PATH.Jobroles);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Space>
              </Col>
            </Row>

            {/* Second Row */}
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>ID :</Text>
              </Col>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text type="secondary">{formData?.id}</Text>
              </Col>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>Title :</Text>
              </Col>
              <Col span={6}>
                {isEditing ? (
                  <Input
                    value={formData?.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                  />
                ) : (
                  <Text type="secondary">{formData?.title}</Text>
                )}
              </Col>
            </Row>

            {/* Third Row */}
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>Description :</Text>
              </Col>
              <Col span={6}>
                {isEditing ? (
                  <Input
                    value={formData?.job_description}
                    onChange={(e) =>
                      handleChange("job_description", e.target.value)
                    }
                  />
                ) : (
                  <Text type="secondary">{formData?.job_description}</Text>
                )}
              </Col>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>Questions :</Text>
              </Col>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text type="secondary">
                  {jobDetails?.job_questions?.length || "0"}
                </Text>
              </Col>
            </Row>

            {/* Fourth Row */}
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>Created At :</Text>
              </Col>
              <Col span={6}>{formData?.created_at?.toString()}</Col>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <Text strong>Status :</Text>
              </Col>
              <Col span={6}>
                {isEditing ? (
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    checked={formData?.is_active}
                    onChange={(value) => handleChange("is_active", value)}
                    style={switchStyle}
                  />
                ) : (
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    checked={formData?.is_active}
                    disabled
                    style={switchStyle}
                  />
                )}
              </Col>
            </Row>
          </Card>

          <Modal
            open={emailModalOpen}
            okText="Save"
            title="Set Email Template"
            onOk={async () => {
              try {
                await changeEmailTemplate(formData?.email_template);
                openNotification("Template changed");
              } catch (e) {
                console.log("error", e);
              } finally {
                setEmailModalOpen(false);
              }
            }}
            onCancel={() => setEmailModalOpen(false)}
          >
            {console.log(formData)}
            <TextArea
              value={formData?.email_template || ""}
              onChange={(e) =>
                setFormData({ ...formData, email_template: e.target.value })
              }
              rows={15}
              style={{ margin: 10 }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default JobProfileDetails;
