/* eslint-disable import/no-unresolved */

import React from "react";
import { TableOptionWrapper } from "./styles";
import Typography from "common/Typography";
import Button from "common/Button";
import { useNavigate } from "react-router-dom";

const TableOptions = ({
  title,
  path,
  btnText,
  secondBtnText = null,
  secondBtnPath = null,
}) => {
  const navigate = useNavigate();
  return (
    <TableOptionWrapper>
      <Typography level={4}>{title}</Typography>
      <div>
        {secondBtnText && secondBtnPath ? (
          <Button
            style={{ marginRight: 10 }}
            onClick={secondBtnPath ? () => navigate(secondBtnPath) : null}
          >
            {secondBtnText}
          </Button>
        ) : null}
        <Button onClick={path ? () => navigate(path) : null} type="default">
          {btnText}
        </Button>
      </div>
    </TableOptionWrapper>
  );
};

export default TableOptions;
