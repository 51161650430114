export const Admin = {
  interviewList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 80,
      },
      {
        dataIndex: "candidate_name",
        title: "Candidate",
        width: 300,
        navigateToId: true,
      },
      {
        dataIndex: "job_title",
        title: "Job Title",
        width: 300,
      },
      {
        dataIndex: "interview_status",
        title: "Status",
        interviewStatus: true,
        sort: true,
        width: 150,
      },
      {
        dataIndex: "interview_link",
        title: "Actions",
        width: 150,
        zInterviewAction: true,
      },
      {
        dataIndex: "upload_report",
        title: "Report",
        action: true,
        zFileDownload: true
      },
    ],
  },
  interviewResponseList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 50,
      },
      {
        dataIndex: "sequence",
        title: "Seq",
        sort: true,
        width: 50,
      },
      {
        dataIndex: "question",
        title: "Question",
        width: 200,
      },
      {
        dataIndex: "response",
        title: "Response Captured",
        width: 200,
      },
      {
        dataIndex: "ai_generated_transcript",
        title: "Transcript by AI",
        width: 200,
      },
      {
        dataIndex: "screenshot",
        title: "Screenshot Captured",
        width: 500,
      },
      {
        dataIndex: "response_recording_link",
        title: "Response Recording Link",
        width: 400,
        audio: true,
      },
    ],
  },
  jobRoleList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "title",
        title: "Title",
        width: 250,
      },
      {
        dataIndex: "job_description",
        title: "Description",
        width: 350,
      },
      {
        dataIndex: "job_questions",
        title: "No of Questions",
        width: 150,
        align: "center",
        count: true,
      },
      {
        dataIndex: "is_active",
        title: "Status",
        align: "center",
        statusTag: true,
      },
    ],
  },
  companyList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "company_name",
        title: "Company Name",
      },
      {
        dataIndex: "industry",
        title: "Industry",
        align: "center",
      },
      {
        dataIndex: "company_website",
        title: "Website",
        width: 250,
      },
      {
        dataIndex: "company_address",
        title: "Address",
        width: 250,
      },
      {
        dataIndex: "is_active",
        title: "Status",
        statusTag: true,
      },
    ],
  },
  candidateList: {
    selectable: true,
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "first_name",
        title: "First Name",
        width: 250,
      },
      {
        dataIndex: "last_name",
        title: "Last Name",
        width: 250,
      },
      {
        dataIndex: "email",
        title: "Email",
        width: 250,
      },
      {
        dataIndex: "active_status",
        title: "Status",
        statusTag: true,
      },
      {
        dataIndex: "actions",
        title: "Actions",
        zCandidateListActions: true
      }
    ],
  },
  questionList: {
    list: [
      {
        dataIndex: "sequence",
        title: "Sequence",
        width: 150,
        sort: true,
      },
      {
        dataIndex: "id",
        title: "Id",
        width: 100,
      },
      {
        dataIndex: "question",
        title: "Question",
        width: 350,
      },
      {
        dataIndex: "is_verified",
        title: "Status",
        align: "center",
        statusTag: true,
        width: 100,
      },
      {
        dataIndex: "action2",
        title: "Action",
        width: 200,
      },
    ],
  },
};
