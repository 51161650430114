/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Badge } from "antd";
import debugLog from "../env/debugLog";

// export const getImagePath = (_relativePath) => {
//   return require(`../assets/Images/${_relativePath}`);
// };

export const NAComp = (
  <Badge
    status="processing"
    size="medium"
    style={{
      fontSize: ".6rem",
      backgroundColor: "rgb(209 213 219)",
      color: "black",
    }}
    count="NA"
  />
);

export function byteConverter(bytes, decimals, only) {
  const K_UNIT = 1024;
  const SIZES = ["B", "KB", "MB", "GB", "TB", "PB"];
  if (bytes == 0) return "0 Byte";
  if (only === "MB")
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";
  let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  let resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
    " " +
    SIZES[i];
  return resp;
}

export const getISTTime = (time) => {
  let date = new Date(time);
  let istTime = date.toLocaleString();
  return istTime;
};

export const setItemToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getItemFromLocalStorage = (key) => {
  debugLog("getItemFromLocalStorage");
  const rawItem = localStorage.getItem(key);
  debugLog("rawItem", rawItem);
  let parsedItem = JSON.parse(rawItem);
  debugLog("parsedItem", parsedItem);
  return parsedItem;
};

export const removeItemFromLocalStorage = (key = "data") => {
  localStorage.removeItem(key);
};

export const isSuperAdmin = () => {
  try {
    const userData = JSON.parse(localStorage.getItem("data"));
    debugLog(userData);
    return userData?.profile && userData?.profile?.is_superadmin === true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const isCompanyAdmin = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  return userData?.profile && userData?.profile?.is_companyadmin === true;
};
export const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const EmailCheckRegEx = (email) => {
  if (email.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
};

export const getTrimmedValue = (value, maxLength) => {
  return value.length > maxLength
    ? value.substring(0, maxLength) + "..."
    : value;
};

export const applyExport = (moduleType, items, passNotificationText) => {
  let csv = "";
  if (items.length === 0) {
    alert(`Please Select ${moduleType}`);
    return;
  }
  const keys = Object.keys(items[0]);
  csv += keys.join(",") + "\r\n";

  for (let row = 0; row < items.length; row++) {
    const rowData = [];
    for (const key of keys) {
      let value = items[row][key];
      if (typeof value === "object" && value !== null) {
        value = value?.key;
      }
      if (typeof value === "string" && value.includes(",")) {
        value = `"${value}"`;
      }
      rowData.push(value);
    }
    csv += rowData.join(",") + "\r\n";
  }
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8," });
  const objUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", objUrl);
  link.setAttribute("download", `${moduleType}-${Number(new Date())}.csv`);
  link.click();
  passNotificationText("Exported successfully!", "success");
};

export const getStatusColor = (status) => {
  if (status) {
    return "#359E4D";
  } else {
    return "#E72212";
  }
};

const capitalizeSubstring = (str) =>
  str ? str[0].toUpperCase() + str.slice(1) : "";

export const getCapitalizeFirstLetter = (str) => {
  return str && str.length > 0
    ? str.trim().split(" ").map(capitalizeSubstring).join(" ")
    : NAComp;
};

export const getDataIsEmpty = (data) => {
  return data === null || data === undefined || data === "" ? NAComp : data;
};
