/* eslint-disable import/no-unresolved */
import { Button, Form, Select, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { PATH } from "constants/PATH";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const AddInterviewForm = ({
  jobList,
  candidateList,
  handleJobSelect,
  handleCandidateSelect,
  handleCreateInterview,
  tableColumns,
  selectable,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  return (
    <>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={4}>Add Assessment</Typography.Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              navigate(PATH.BulkAddInterviews);
            }}
            style={{ marginRight: 10 }}
          >
            Bulk Create Assessment
          </Button>
          <Button type="primary" onClick={handleCreateInterview}>
            Create Assessment
          </Button>
        </div>
      </span>

      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        size="large"
      >
        <Form.Item
          name="job-profile"
          label="Job Profile"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a Job Profile"
            optionFilterProp="children"
            onChange={handleJobSelect}
          >
            {jobList?.map((job) => (
              <Select.Option key={job?.id} value={job?.id}>
                {job?.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Title level={4}>Candidate List</Title>
        <CustomTable
          columns={tableColumns}
          data={candidateList?.sort((a, b) => b.id - a.id)}
          onRowSelect={(selectedRows) => {
            const selectedRowKeys = selectedRows?.map(({ row }) => row?.id);
            handleCandidateSelect(selectedRowKeys, selectedRows);
          }}
          selectable={selectable}
        />
      </Form>
    </>
  );
};

export default AddInterviewForm;
