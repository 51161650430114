/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import {
  CREATE_QUESTION_URL,
  LIST_QUESTION_URL,
  QUESTION_DETAILS_URL,
  QUESTION_UPDATE_URL,
  VERIFY_QUESTION_STATUS_URL,
} from "constants/api";

const model = {
  state: {
    questions: [],
    loading: false,
    modificationModalState: null,
    error: "",
  },
  reducers: {
    setModificationModalState: (state, payload) => {
      return { ...state, modificationModalState: payload };
    },
    setQuestions: (state, payload) => {
      return {
        ...state,
        questions: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestGetQuestionList(jobId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${LIST_QUESTION_URL}/${jobId}`);
        if (response) {
          this.setLoadingState(false);
          this.setQuestions(response);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async toggleModificationModalState(payload) {
      dispatch.QuestionModel.setModificationModalState(payload);
    },
    async requestCreateQuestion(jobId, state, payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CREATE_QUESTION_URL, payload);
        if (response) {
          this.requestGetQuestionList(jobId);
          return response.json();
        }
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message;
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data;
      }
    },
    async requestGetQuestionDetails(questionId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${QUESTION_DETAILS_URL}/${questionId}`);
        if (response) {
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async requestUpdateQuestionDetails(questionId, payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(
          `${QUESTION_DETAILS_URL}/${questionId}`,
          payload
        );
        if (response) {
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
    async verifyQuestionStatus(questionId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${QUESTION_DETAILS_URL}/${questionId}`);
        if (response) {
          this.setLoadingState(false);
        }
        return response;
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data;
      }
    },
  }),
};
export default model;
